import '../styles/ClickableTable.scss';
import {useState, useEffect} from 'react';
import Checkbox from '../components/Checkbox';

const ClickableTable = (props) => {

    const [sortBy, setSortBy] = useState(props.sortBy===undefined? 1: props.sortBy);
    const [asc, setAsc] = useState(props.asc===undefined? 1: props.asc);

    let headers = props.headers
    const data = props.data.sort((a, b) => {
        if (sortBy === -1) {
            return asc;
        }

        const ai = a[sortBy];
        const bi = b[sortBy];

        if (typeof ai === "string") {
            return asc * ai.localeCompare(bi);
        } else {
            if(ai > bi) return asc;
            if(ai < bi) return -asc;
        }
        return 0;
    });

    const headerClick = (index) => {
        if (index === sortBy) {
            setAsc(-asc);
        } else {
            setSortBy(index);
        }
    }

    const onClick = props.onClick? props.onClick: () => {};

    return (
        <table className="ClickableTable">
            <tbody>
                <TableHeader data={headers} onClick={headerClick}/>
                {
                    data.map((val, index) => {
                        return <TableRow key={index} data={val.slice(0,4)} onClick={onClick} held={val[4]} setHeld={(v) => {props.setHeld(val[0], v)}}/>;
                    })
                }
            </tbody>
        </table>
    );
}

const TableHeader = (props) => {
    const data = props.data

    const onClick = props.onClick? props.onClick: () => {};

    return (
        <tr className="TableHeader">
            {
                data.map((val, index) => {
                    return <th key={index} onClick={() => {onClick(index+1)}}>{val}</th>;
                })
            }
            <th>Hold</th>
        </tr>
    );
}

const TableRow = (props) => {
    const data = props.data;
    const id = data[0];

    return (
        <tr className="TableData" onClick={() => {props.onClick(id)}}>
            {
                data.slice(1).map((val, index) => {
                    return <td key={index}>{val}</td>;
                })
            }
                <td>
                    <Checkbox checked={props.held} setChecked={props.setHeld}/>
                </td>
        </tr>
    );
}

export default ClickableTable;
