import '../styles/TextInput.scss';
import {useRef} from 'react';

const CurrencyInput = (props) => {
    const innerRef = useRef(null);

    const placeholder = props.placeholder? props.placeholder: "";
    const ref = props.innerRef || innerRef;

    const onBlur = () => {
        if (ref.current.value !== "") {
            ref.current.value = Number(ref.current.value).toFixed(2);
            if (Number(ref.current.value) < 0) {
                ref.current.value = "0.00"
            }
        }
    }

    const defaultValue = props.value;

    return (
        <div className="TextInput CurrencyInput">
            <input
                ref={ref}
                type="number"
                min="0.0"
                step="0.01"
                required={true}
                onBlur={onBlur}
                defaultValue={defaultValue}
            />
            <label>{placeholder}</label>
        </div>
    );
}

export default CurrencyInput;