import '../styles/TextInput.scss';

const TextInput = (props) => {

    const placeholder = props.placeholder? props.placeholder: "";
    const ref = props.innerRef;
    const value = props.value? props.value: "";
    const type = props.type? props.type: "text";
    const autocomplete = props.autocomplete? props.autocomplete: "";

    return (
        <div className="TextInput">
            <input ref={ref} required={true} defaultValue={value} type={type} autoComplete={autocomplete}/>
            <label>{placeholder}</label>
        </div>
    );
}

export default TextInput;