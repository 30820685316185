import '../styles/Modal.scss';

import Button from '../components/Button';

const Modal = (props) => {

    const title = props.title? props.title: "";
    const primaryText = props.primaryText? props.primaryText: "";
    const secondaryText = props.secondaryText? props.secondaryText: "";
    const ternaryText = props.ternaryText? props.ternaryText: "";

    const onPrimary = props.onPrimary? props.onPrimary: () => {};
    const onSecondary = props.onSecondary? props.onSecondary: () => {};
    const onTernary = props.onTernary? props.onTernary: () => {};

    const visible = "visible" in props? props.visible: true;
    const error = "error" in props? props.error: "";

    const onSubmit = props.onSubmit? props.onSubmit: () => {};

    return (
        <div className={"modal-scrim" + (visible? "": " hidden")}>
            <form className="modal-card" onSubmit={onSubmit}>
                <div className="modal-title">{title}</div>
                <div className="modal-items">
                    {props.children}
                    <p className="Error">{error}</p>
                </div>
                <div className="modal-top-action">
                    <Button  text={ternaryText} type={ternaryText? "warn": "none"} onClick={onTernary}/>
                </div>
                <div className="modal-bottom-action">
                    <Button text={secondaryText} type="text" onClick={onSecondary}/>
                    <Button text={primaryText} onClick={onPrimary} submit={true}/>
                </div>
            </form>
        </div>
    );
}

export default Modal;