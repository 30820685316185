import '../styles/login.scss';

import {useState, useEffect, useRef} from 'react';

import Button from '../components/Button';
import TextInput from '../components/TextInput';
import {ReactComponent as Logo} from '../assets/logo.svg';
import {Redirect} from 'react-router-dom';

const PasswordReset = (props) => {
    const [error, setError] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);

    const passwordRef = useRef(null);
    const verifyPasswordRef = useRef(null);
    
    const api = props.API;
    const id = props.match.params.id;

    useEffect(() => {
        const alert = (err) => {
            setError(err);
            setLoggedIn(api.loggedIn);
        }

        api.addListener(alert);
        return () => {api.removeListener(alert)}
    }, [api]);

    // Validate the user input
    const validate = (password, verify) => {
        if (password === "") {
            setError("Password cannot be empty");
            return false;
        } else if (password.length < 7) {
            setError("Password must be seven characters or longer");
            return false;
        } else if (password !== verify) {
            setError("Passwords do not match");
            return false;
        }

        return true;
    }

    // Reset the password
    const resetPassword = async () => {
        const password = passwordRef.current.value;
        const verify = verifyPasswordRef.current.value;

        if (validate(password, verify)) {
            await api.resetPassword(id, password);
        }
    }

    return (
        <div className="LoginScreen">
            {loggedIn? <Redirect to={{pathname: "/cloud"}} />: null}
            <div className="LoginHeader">
                <Logo />
                <p>High Desert Dairy</p>
            </div>
            <div className="LoginCard">
                <p>Reset Password</p>
                <form>
                <TextInput placeholder="Password" type="password" autocomplete="new-password" innerRef={passwordRef}/>
                <TextInput placeholder="Verify Password" type="password" autocomplete="new-password" innerRef={verifyPasswordRef}/>
                    <p className="Error">{error}</p>
                    <div className="Buttons">
                        <Button text="Reset Password" onClick={resetPassword} submit={true}/>
                    </div>
                </form>
            </div>
        </div>
    );
}



export default PasswordReset;
