import {useEffect, useState, useRef} from 'react';

import EditableList from '../components/EditableList';
import Modal from '../components/Modal';
import TextInput from '../components/TextInput';
import API from '../util/AdminAPI'
import {checkEmail} from '../util';

const AdminSettings = (props) => {
    const api: API = props.API;

    // Application state
    const [regions, setRegions] = useState([]);
    const [breeds, setBreeds] = useState([])
    const [ilics, setIlics] = useState([]);
    const [PCs, setPCs] = useState([]);
    const [feeds, setFeeds] = useState([]);
    const [trays, setTrays] = useState([]);
    const [settings, setSettings] = useState([]);
    const [settingIndex, setSettingIndex] = useState(0);
    const [pcIndex, setPcIndex] = useState(0);
    const [error, setError] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [settingModalVisible, setSettingModalVisible] = useState(false);

    // Input Refs
    const regionNameRef = useRef(null);
    const breedNameRef = useRef(null);
    const feedNameRef = useRef(null);
    const iNameRef = useRef(null);
    const descRef = useRef(null);
    const emailRef = useRef(null);
    const valueRef = useRef(null);

    const trayNumRef = useRef(null);
    const trayWeightRef = useRef(null);

    // Validation functions
    const validateRegion = () => {
        if (regionNameRef.current.value === "") {
            return "Name cannot be empty";
        }

        return "";
    }

    const validateBreed = () => {
        if (breedNameRef.current.value === "") {
            return "Name cannot be empty";
        }

        return ""
    }

    const validateFeed = () => {
        if (feedNameRef.current.value === "") {
            return "Name cannot be empty";
        }

        return ""
    }

    const validateTray = () => {
        if (trayNumRef.current.value === "") {
            return "Tray Num cannot be empty";
        }

        if (trayWeightRef.current.value === "") {
            return "Tray Weight cannot be empty";
        }

        return "";
    }

    const validateIlic = () => {
        if (iNameRef.current.value === "") {
            return "Name cannot be empty";
        } else if (descRef.current.value === "") {
            return "Description cannot be empty";
        }

        return "";
    }

    const validatePC = () => {
        if (!checkEmail(emailRef.current.value)) {
            setError("Invalid Email");
            return false;
        }

        return true;
    }

    const populateRegion = (index) => {
        let region;

        if (index === -1) {
            region = {name: ""}
        } else {
            region = regions[index];
        }

        regionNameRef.current.value = region.name;
    }

    const populateBreed = (index) => {
        let breed;

        if (index === -1) {
            breed = {name: ""}
        } else {
            breed = breeds[index];
        }

        breedNameRef.current.value = breed.name;
    }

    const populateIlic = (index) => {
        let ilic;

        if (index === -1) {
            ilic = {name: "", description: ""}
        } else {
            ilic = ilics[index];
        }

        iNameRef.current.value = ilic.name;
        descRef.current.value = ilic.description;
    }

    const populateFeed = (index) => {
        let feed;

        if (index === -1) {
            feed = {name: ""}
        } else {
            feed = feeds[index];
        }

        feedNameRef.current.value = feed.name;
    }

    const populateTray = (index) => {
        let tray;

        if (index === -1) {
            tray = {id: "", weight: ""}
        } else {
            tray = trays[index];
        }

        trayNumRef.current.value = tray.id;
        trayWeightRef.current.value = tray.weight;
    }

    // API Functions
    const getRegions = async () => {
        const regions = await api.getRegions();
        setRegions(regions);
    }

    const getBreeds = async () => {
        const breeds = await api.getBreeds();
        setBreeds(breeds);
    }

    const getFeeds = async () => {
        setFeeds(await api.getFeedTypes());
    }

    const getTrays = async () => {
        let ts = await api.getTrays();
        setTrays(ts.map(e => {
            e.name = e.id + " : " + e.weight.toFixed(2);
            return e;
        }));
    }

    const getInvoiceLineItemCategories = async () => {
        const ilics = await api.getInvoiceLineItemCategories();
        setIlics(ilics);
    }

    const getPCs = async () => {
        setPCs(await api.getProcessingCenters());
    }

    const getSettings = async () => {
        setSettings(await api.getAdminSettings());
    }

    const fetchData = async () => {
        await getTrays();
        await getFeeds();
        await getBreeds();
        await getRegions();
        await getInvoiceLineItemCategories();
        await getPCs();
        await getSettings();
    }

    const newRegion = async () => {
        const name = regionNameRef.current.value;
        await api.newRegion(name);
        await getRegions();
        clear();
    }

    const updateRegion = async (index) => {
        const id = regions[index].id;
        const name = regionNameRef.current.value;

        await api.updateRegion(id, name);
        await getRegions();
        clear();
    }

    const deleteRegion = async (index) => {
        const id = regions[index].id;
        await api.deleteRegion(id);
        await getRegions();
        clear();
    }

    const newBreed = async () => {
        const name = breedNameRef.current.value;
        await api.newBreed(name);
        await getBreeds();
        clear();

    }

    const updateBreed = async (index) => {
        const id = breeds[index].id;
        const name = breedNameRef.current.value;
        await api.updateBreed(id, name);
        await getBreeds();
        clear();
    }

    const deleteBreed = async (index) => {
        const id = breeds[index].id;
        await api.deleteBreed(id);
        await getBreeds();
        clear();
    }

    const newFeed = async () => {
        const name = feedNameRef.current.value;
        await api.newFeedType(name);
        await getFeeds();
        clear();
    }

    const updateFeed = async (index) => {
        const id = feeds[index].id;
        const name = feedNameRef.current.value;
        await api.updateFeedType(id, name);
        await getFeeds();
        clear();
    }

    const deleteFeed = async (index) => {
        const id = feeds[index].id;
        await api.deleteFeedType(id);
        await getFeeds();
        clear();
    }

    const newTray = async () => {
        const id = trayNumRef.current.value;
        const weight = trayWeightRef.current.value;

        await api.newTray(id, weight);
        await getTrays();

        clear();
    }

    const updateTray = async (index) => {
        const id = trays[index].id;
        const weight = trayWeightRef.current.value;
        await api.updateTray(id, weight);
        await getTrays();
        clear();
    }

    const deleteTray = async (index) => {
        const id = trays[index].id;
        await api.deleteTray(id);
        await getTrays();
    }

    const newIlic = async () => {
        const name = iNameRef.current.value;
        const desc = descRef.current.value;
        await api.newInvoiceLineItemCategory(name, desc);
        await getInvoiceLineItemCategories();
        clear();
    }

    const editIlic = async (index) => {
        const id = ilics[index].id;
        const name = iNameRef.current.value;
        const description = descRef.current.value;
        await api.updateInvoiceLineItemCategory(id, name, description);
        await getInvoiceLineItemCategories();
        clear();
    }

    const deleteIlic = async (index) => {
        const id = ilics[index].id;
        await api.deleteInvoiceLineItemCategory(id);
        await getInvoiceLineItemCategories();
        clear();
    }

    const clear = () => {
        regionNameRef.current.value = "";
        breedNameRef.current.value = "";
        feedNameRef.current.value = "";
        iNameRef.current.value = "";
        descRef.current.value = "";
        trayNumRef.current.value = "";
        trayWeightRef.current.value = "";
    }

    const hideModal = () => {
        setModalVisible(false);
        setSettingModalVisible(false);
    }

    const editPC = (index) => {
        const pc = PCs[index];
        emailRef.current.value = pc.contact.email;
        setModalVisible(true);
        setPcIndex(index);
    }

    const updateProcessingCenter = async () => {
        const email = emailRef.current.value;
        const contactId = PCs[pcIndex].contact.id;
        await api.updateContact(contactId, {email: email});
        await getPCs();
        hideModal();
    }

    const editSetting = (index) => {
        const setting = settings[index];
        valueRef.current.value = setting.value;
        setSettingModalVisible(true);
        setSettingIndex(index);
    }

    const updateSetting = async () => {
        const key = settings[settingIndex].key;
        const value = valueRef.current.value;

        await api.updateSetting(key, value);
        await getSettings();
        hideModal();
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="AdminSettingsScreen">
            {api.render()}
            <EditableList
                title="Regions"
                items={regions}
                newItem={newRegion}
                editItem={updateRegion}
                deleteItem={deleteRegion}
                validate={validateRegion}
                populate={populateRegion}
            >
                <TextInput innerRef={regionNameRef} placeholder="Name" />
            </EditableList>
            <EditableList
                title="Breeds"
                items={breeds}
                newItem={newBreed}
                editItem={updateBreed}
                deleteItem={deleteBreed}
                validate={validateBreed}
                populate={populateBreed}
            >
                <TextInput innerRef={breedNameRef} placeholder="Name" />
            </EditableList>
            <EditableList
                title="Feed Types"
                items={feeds}
                newItem={newFeed}
                editItem={updateFeed}
                deleteItem={deleteFeed}
                validate={validateFeed}
                populate={populateFeed}
            >
                <TextInput innerRef={feedNameRef} placeholder="Name" />
            </EditableList>
            <EditableList
                title="Line Item Categories"
                items={ilics}
                newItem={newIlic}
                editItem={editIlic}
                deleteItem={deleteIlic}
                validate={validateIlic}
                populate={populateIlic}
            >
                <TextInput innerRef={iNameRef} placeholder="Name" />
                <TextInput innerRef={descRef} placeholder="Description (On Invoice)" />
            </EditableList>
            <EditableList
                title="Trays"
                items={trays}
                newItem={newTray}
                editItem={updateTray}
                deleteItem={deleteTray}
                validate={validateTray}
                populate={populateTray}
            >
                <TextInput innerRef={trayNumRef} type="number" placeholder="Num" />
                <TextInput innerRef={trayWeightRef} type="number" placeholder="Weight" />
            </EditableList>
            <h3>Processing Centers</h3>
            {
                PCs.map((pc, index) => {
                    return (
                        <div className="ProcessingCenterCard" onClick={() => {editPC(index)}}>
                            <p style={{margin: 0}} className="name">{pc.name}</p>
                            <p style={{margin: 0}} className="email">{pc.contact.email}</p>
                        </div>
                    );
                })
            }
            <h3>Miscellaneous Settings</h3>
            {
                settings.map((setting, index) => {
                    const key = setting.key.replaceAll("_", " ").toUpperCase();
                    return (
                        <div className="ProcessingCenterCard" onClick={() => {editSetting(index)}}>
                            <p style={{margin: 0}} className="name">{key}</p>
                            <p style={{margin: 0}} className="email">{setting.value}</p>
                        </div>
                    )
                })
            }
            <Modal
                title="Edit Processing Center"
                visible={modalVisible}
                primaryText="Save"
                onPrimary={updateProcessingCenter}
                secondaryText="Cancel"
                onSecondary={hideModal}
            >
                <p>{PCs.length > 0? PCs[pcIndex].name: ""}</p>
                <TextInput placeholder="Email" innerRef={emailRef}/>
            </Modal>
            <Modal
                title={"Edit " + (settings.length > 0? settings[settingIndex].key.replaceAll("_", " ").toUpperCase(): "")}
                visible={settingModalVisible}
                primaryText="Save"
                onPrimary={updateSetting}
                secondaryText="Cancel"
                onSecondary={hideModal}
            >
                <TextInput placeholder="Value" innerRef={valueRef}/>
            </Modal>
        </div>
        
    );
}

export default AdminSettings;
