import {useState, useRef, useEffect} from 'react';
import {checkEmail} from '../util';

import SearchBox from '../components/SearchBox';
import Button from '../components/Button';
import ClickableTable from '../components/ClickableTable';
import Modal from '../components/Modal';
import TextInput from '../components/TextInput';
import CurrencyInput from '../components/CurrencyInput';
import CustomerDetail from '../components/CustomerDetail';
import Checkbox from '../components/Checkbox';
import Textarea from '../components/Textarea';
import SelectInput from '../components/SelectInput';
import API from '../util/AdminAPI';
import states from '../util/states';
import ContactCard from '../components/ContactCard';

const Customers = (props) => {

    const api: API = props.API;

    // Data based state
    const headers = ["ID", "Name", "Email", "Herds", "Billing Rate"];
    const [customers, setCustomers] = useState([]);
    const [processingCenters, setProcessingCenters] = useState([]);
    const [regions, setRegions] = useState([]);
    const [breeds, setBreeds] = useState([]);
    const [categories, setCategories] = useState([]);
    const [pcIndex, setPcIndex] = useState(0);
    const [regionIndex, setRegionIndex] = useState(0);
    const [breedIndex, setBreedIndex] = useState(0);
    const [stateIndex, setStateIndex] = useState(0);
    const [categoryIndex, setCategoryIndex] = useState(0);

    // Other state
    const [newCustVisible, setNewCustVisible] = useState(false);
    const [editCustVisible, setEditCustVisible] = useState(false);

    const [newHerdVisible, setNewHerdVisible] = useState(false);
    const [editHerdVisible, setEditHerdVisible] = useState(false);

    const [newRILVisible, setNewRILVisible] = useState(false);
    const [editRILVisible, setEditRILVisible] = useState(false);

    const empty_state = [{name: "None", abbreviation: ""}]
    const state_options = empty_state.concat(states)

    const [herdIndex, setHerdIndex] = useState(-1);
    const [herdContactIndex, setHerdContactIndex] = useState(0);
    const [globalContactIndex, setGlobalContactIndex] = useState(0);
    const [newModalVisible, setNewModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [modalError, setModalError] = useState("");

    // Stats state
    const [statsVisible, setStatsVisible] = useState(false);
    const [recentResults, setRecentResults] = useState([]);

    const [selectedID, setSelectedID] = useState(null);
    const [selectedId, setSelectedId] = useState(-1);
    const [contact, setContact] = useState({addr1:"", addr2:"", city:"", state:"", zipcode:"", mobile_num:"", email:""});
    const [selectedHerdID, setSelectedHerdID] = useState(null);
    const [selectedRILID, setSelectedRILID] = useState(null);
    const [showObsolete, setShowObsolete] = useState(false);
    const [sameBillingAddr, setSameBillingAddr] = useState(true);

    // Refs Customers
    const newNameRef = useRef(null);
    const newEmailRef = useRef(null);
    const newBRRef = useRef(null);
    const newAddr1Ref = useRef(null);
    const newAddr2Ref = useRef(null);
    const newCityRef = useRef(null);
    const newZipcodeRef = useRef(null);
    const newMobileNumRef = useRef(null);
    const newUSDAIDRef = useRef(null);

    const editNameRef = useRef(null);
    const editEmailRef = useRef(null);
    const editBRRef = useRef(null);
    const editAddr1Ref = useRef(null);
    const editAddr2Ref = useRef(null);
    const editCityRef = useRef(null);
    const editZipcodeRef = useRef(null);
    const editMobileNumRef = useRef(null);
    const editUSDAIDRef = useRef(null);

    // Refs Herds
    const [amChecked, setAMChecked] = useState(false);
    const [officialChecked, setOfficialChecked] = useState(false);
    const [promptChecked, setPromptChecked] = useState(false);
    const [dhiaChargeChecked, setDhiaChargeChecked] = useState(false);

    const newHerdNameRef = useRef(null);
    const newFilecodeRef = useRef(null);
    const newHerdMilkingsRef = useRef(null);
    const newHerdWeightFactorRef = useRef(null);
    const newHerdCommentsRef = useRef(null);

    const editHerdNameRef = useRef(null);
    const editFilecodeRef = useRef(null);
    const editHerdMilkingsRef = useRef(null);
    const editHerdWeightFactorRef = useRef(null);
    const editHerdCommentsRef = useRef(null);

    const newRILAmountRef = useRef(null);
    const editRILAmountRef = useRef(null);

    const newContactNameRef = useRef(null);
    const newContactEmailRef = useRef(null);
    const newContactMobileNumRef = useRef(null);
    const newContactAddr1Ref = useRef(null);
    const newContactAddr2Ref = useRef(null);
    const newContactCityRef = useRef(null);
    const newContactZipcodeRef = useRef(null);

    const editContactNameRef = useRef(null);
    const editContactEmailRef = useRef(null);
    const editContactMobileNumRef = useRef(null);
    const editContactAddr1Ref = useRef(null);
    const editContactAddr2Ref = useRef(null);
    const editContactCityRef = useRef(null);
    const editContactZipcodeRef = useRef(null);

    // Other Refs
    const scrollRef = props.scrollRef;

    // API Functions
    const getRecentResults = async (id) => {
        const res = await api.getRecentHerdResults(id);
        res.reverse();
        setRecentResults(res);
    }

    // For Customers...
    const getCustomers = async () => {
        setCustomers(await api.getCustomers());
    }

    const refreshCustomer = async (idx) => {
        let cust = await api.getCustomer(customers[idx].id);
        let new_custs = customers.slice();
        new_custs[idx] = cust;

        setCustomers(new_custs);
    }

    const addContact = async () => {
        const name = newContactNameRef.current.value;
        const email = newContactEmailRef.current.value;
        const mobile_num = newContactMobileNumRef.current.value;
        const addr1 = newContactAddr1Ref.current.value;
        const addr2 = newContactAddr2Ref.current.value;
        const city = newContactCityRef.current.value;
        const state = state_options[stateIndex].abbreviation;
        const zipcode = newContactZipcodeRef.current.value;
        const cust = customers[selectedId];

        if (validateContact(name, email)) {
            if (herdIndex === -1) {
                await api.newCustomerContact(cust.id, name, email, mobile_num, addr1, addr2, city, state, zipcode);
            } else {
                const herd = cust.herds[herdIndex];
                await api.newHerdContact(herd.id, name, email, mobile_num, addr1, addr2, city, state, zipcode);
            }

            await refreshCustomer(selectedId);
            hideModal();
        }
    }

    const editContact = async () => {
        const data = {
            name: editContactNameRef.current.value,
            email: editContactEmailRef.current.value,
            mobile_num: editContactMobileNumRef.current.value,
            addr1: editContactAddr1Ref.current.value,
            addr2: editContactAddr2Ref.current.value,
            city: editContactCityRef.current.value,
            state: state_options[stateIndex].abbreviation,
            zipcode: editContactZipcodeRef.current.value
        }

        const cust = customers[selectedId];

        if (herdIndex === -1) {
            let id
            if (globalContactIndex === -1) {
                id = cust.contact.id
            } else {
                id = cust.contacts[globalContactIndex].id;
            }
            await api.updateContact(id, data);
        } else {
            const id = cust.herds[herdIndex].contacts[herdContactIndex].id;
            await api.updateContact(id, data);
        }
        await refreshCustomer(selectedId);
        hideModal();
    }

    const deleteContact = async () => {
        const cust = customers[selectedId];
        if (herdIndex === -1) {
            let id
            if (globalContactIndex === -1) {
                id = cust.contact.id
            } else {
                id = cust.contacts[globalContactIndex].id;
            }
            await api.deleteCustomerContact(id, cust.id);
        } else {
            const id = cust.herds[herdIndex].contacts[herdContactIndex].id;
            await api.deleteHerdContact(id, cust.herds[herdIndex].id);
        }

        await refreshCustomer(selectedId);
        hideModal();
    }

    const createCustomer = async () => {
        const name = newNameRef.current.value;
        const email = newEmailRef.current.value;
        const billing_rate = newBRRef.current.value;
        const addr1 = newAddr1Ref.current.value;
        const addr2 = newAddr2Ref.current.value;
        const city = newCityRef.current.value;
        const state = states[stateIndex].abbreviation;
        const zipcode = newZipcodeRef.current.value;
        const mobile_num = newMobileNumRef.current.value;

        if (validateEditCustomer(
            name,
            email,
            billing_rate,
            mobile_num
        )) {
            let res = await api.newCustomer(
                name,
                email,
                billing_rate,
                addr1,
                addr2,
                city,
                state,
                zipcode,
                mobile_num
            );

            if ('error' in res) {
                setModalError(res.error);
            } else {
                await getCustomers();
                hideModal();
            }
        }
    }

    const updateCustomer = async () => {
        const name = editNameRef.current.value;
        const email = editEmailRef.current.value;
        const billing_rate = editBRRef.current.value;
        const mobile_num = editMobileNumRef.current.value;

        const curr_cust_idx = customers.findIndex(cust => cust.id === selectedID)
        const curr_cust = customers[curr_cust_idx];

        if (
            name !== curr_cust.name || 
            email !== curr_cust.email || 
            billing_rate !== curr_cust.billing_rate ||
            mobile_num !== contact.mobile_num
        ) {
            if (validateEditCustomer(
                name,
                email,
                billing_rate,
                mobile_num
            )) {
                await api.updateCustomer(selectedID, {
                    name: name,
                    email: email,
                    billing_rate: billing_rate
                });

                await api.updateContact(curr_cust.contact_id, {
                    name: name,
                    mobile_num: mobile_num
                });

                await refreshCustomer(curr_cust_idx);
                hideModal();
            }
        }
    }

    const deleteCustomer = async () => {
        const curr_cust_idx = customers.findIndex(cust => cust.id === selectedID);
        const curr_cust = customers[curr_cust_idx];
        if (curr_cust && curr_cust.obsolete) {
            await api.updateCustomer(selectedID, {'is_obsolete': false})
        } else {
            await api.deleteCustomer(selectedID);
        }

        await refreshCustomer(curr_cust_idx);
        hideModal();
        setSelectedID(null);
    }

    // For Herds...
    const createHerd = async () => {
        const curr_cust_idx = customers.findIndex(cust => cust.id === selectedID);
        const name = newHerdNameRef.current.value;
        const filecode = newFilecodeRef.current.value;
        const processingID = processingCenters[pcIndex].id;
        const regionID = regions[regionIndex].id;
        const breedID = breeds[breedIndex].id;
        const milkings = newHerdMilkingsRef.current.value;
        const comments = newHerdCommentsRef.current.value;
        const custID = selectedID;
        const promptTankWeights = promptChecked;
        const dhiaCharge = dhiaChargeChecked;
        const weight_factor = newHerdWeightFactorRef.current.value;
        let usdaID;

        let billingAddr;
        let billingAddr2;
        let billingCity;
        let billingState;
        let billingZipcode;

        if (newUSDAIDRef.current) {
            usdaID=newUSDAIDRef.current.value;
        } else {
            usdaID=null;
        }

        if (!sameBillingAddr) {
            billingAddr = editAddr1Ref.current.value;
            billingAddr2 = editAddr2Ref.current.value;
            billingCity = editCityRef.current.value;
            billingState = states[stateIndex].abbreviation;
            billingZipcode = editZipcodeRef.current.value;
        }

        if (validateEditherd(name, milkings, usdaID, filecode)) {
            await api.newHerd(custID, name, regionID, breedID, processingID, amChecked, officialChecked, usdaID, milkings, comments, weight_factor, promptTankWeights, dhiaCharge, billingAddr, billingAddr2, billingCity, billingState, billingZipcode, filecode);

            await refreshCustomer(curr_cust_idx);
            hideModal();
        }
    }

    const updateHerd = async () => {
        const curr_cust_idx = customers.findIndex(cust => cust.id === selectedID);
        const name = editHerdNameRef.current.value;
        const filecode = editFilecodeRef.current.value;
        const processingID = processingCenters[pcIndex].id
        const regionID = regions[regionIndex].id;
        const breedID = breeds[breedIndex].id;
        const milkings = editHerdMilkingsRef.current.value;
        const association_member = amChecked;
        const official = officialChecked;
        const comments = editHerdCommentsRef.current.value;
        const promptTankWeights = promptChecked;
        const dhiaCharge= dhiaChargeChecked;
        const weight_factor = editHerdWeightFactorRef.current.value;
        const usdaID = editUSDAIDRef.current? editUSDAIDRef.current.value: null;

        const curr_cust = customers.find(cust => cust.id === selectedID);
        const curr_herd = curr_cust.herds.find(herd => herd.id === selectedHerdID);

        const billingAddr = editAddr1Ref.current.value;
        const billingAddr2 = editAddr2Ref.current.value;
        const billingCity = editCityRef.current.value;
        const billingState = states[stateIndex].abbreviation;
        const billingZipcode = editZipcodeRef.current.value;

        if (validateEditherd(name, milkings, usdaID, filecode)) {
            await api.updateHerd(curr_herd.id, {
                name: name,
                region_id: regionID,
                breed_id: breedID,
                processing_center_id: processingID,
                is_association_member: association_member,
                is_official: official,
                usda_id: usdaID,
                milkings_per_day: milkings,
                comments: comments,
                prompt_tank_weights: promptTankWeights,
                weight_factor: weight_factor,
                dhia_charge: dhiaCharge,
                billing_address: billingAddr,
                billing_address2: billingAddr2,
                billing_city: billingCity,
                billing_state: billingState,
                billing_zipcode: billingZipcode,
                filecode: filecode
            });

            await refreshCustomer(curr_cust_idx);
            hideModal();
        }
    }

    const deleteHerd = async () => {
        const curr_cust_idx = customers.findIndex(cust => cust.id === selectedID);
        const curr_cust = customers[curr_cust_idx];
        const curr_herd = curr_cust.herds.find(herd => herd.id === selectedHerdID);

        if (curr_herd && curr_herd.obsolete) {
            await api.updateHerd(selectedHerdID, {'is_obsolete': false});
        } else {
            await api.deleteHerd(selectedHerdID);
        }

        await refreshCustomer(curr_cust_idx())
        hideModal();
    }

    // For misc...
    const getProcessingCenters = async () => {
        const pc = [{id: null, name:"None"}]
        const res = await api.getProcessingCenters();
        const pcs = pc.concat(res);
        setProcessingCenters(pcs);
    }

    const getRegions = async () => {
        const def = [{id: null, name: "None"}]
        const res = await api.getRegions();
        const regions = def.concat(res);
        setRegions(regions);
    }

    const getBreeds = async () => {
        const def = [{id: null, name: "None"}];
        const res = await api.getBreeds();
        const breeds = def.concat(res? res: []);
        setBreeds(breeds);
    }

    const getCategories = async () => {
        setCategories(await api.getInvoiceLineItemCategories());
    }

    const newRIL = async () => {
        const amount = newRILAmountRef.current.value;
        if (validateRIL(amount)) {
            const curr_cust_idx = customers.findIndex(cust => cust.id === selectedID);
            await api.newRecurringLineItem(selectedID, categories[categoryIndex].id, amount);
            await refreshCustomer(curr_cust_idx);
            hideModal();
        }
    }

    const updateRIL = async () => {
        const amount = editRILAmountRef.current.value;
        if (validateRIL(amount)) {
            const curr_cust_idx = customers.findIndex(cust => cust.id === selectedID);
            await api.updateRecurringLineitem(selectedRILID, categories[categoryIndex].id, amount);
            await refreshCustomer(curr_cust_idx);
            hideModal();
        }
    }

    const deleteRIL = async () => {
        const curr_cust_idx = customers.findIndex(cust => cust.id === selectedID);
        await api.deleteRecurringLineItem(selectedRILID);
        await refreshCustomer(curr_cust_idx);
        hideModal();
    }

    // Modal commands
    const showCustomerModal = () => {
        setEditCustVisible(true);

        const cust = customers.find(cust => cust.id === selectedID);
        editNameRef.current.value = cust.name;
        editEmailRef.current.value = cust.email;
        editBRRef.current.value = cust.billing_rate;
        editMobileNumRef.current.value = cust.contact.mobile_num;

        setStateIndex(states.findIndex(e => e.abbreviation === cust.contact.state));
    }

    const showNewModal = () => {
        setNewCustVisible(true);
    }

    const showHerdModal = (index) => {
        const curr_cust = customers.find(cust => cust.id === selectedID);
        const curr_herd = curr_cust.herds[index];
        setSelectedHerdID(curr_herd.id);

        const name = curr_herd.name;
        const filecode = curr_herd.filecode;
        const processing_center = curr_herd.processing_center === null? 0: processingCenters.findIndex(pc => pc.id === curr_herd.processing_center.id);
        const is_association_member = curr_herd.is_association_member;
        const is_official = curr_herd.is_official;
        const region = curr_herd.region? regions.findIndex(r => r.id === curr_herd.region.id): 0;
        const breed = curr_herd.breed? breeds.findIndex(b => b.id === curr_herd.breed.id): 0;
        const milkings = curr_herd.milkings_per_day || 0;
        const comments = curr_herd.comments;
        const prompt_tank_weights = curr_herd.prompt_tank_weights;
        const weight_factor = curr_herd.weight_factor;
        const usdaID = curr_herd.usda_id;
        const dhiaCharge = curr_herd.dhia_charge;
        const addr1 = curr_herd.billing_addr;
        const addr2 = curr_herd.billing_addr2;
        const city = curr_herd.billing_city;
        const state = curr_herd.billing_state;
        const zipcode = curr_herd.billing_zipcode;
        const state_index = states.findIndex((s) => {return s.abbreviation === state}) || 0;

        setOfficialChecked(is_official);
        editHerdNameRef.current.value = name;
        editFilecodeRef.current.value = filecode;
        setPcIndex(processing_center);
        setRegionIndex(region);
        setBreedIndex(breed);
        editHerdMilkingsRef.current.value = milkings;
        editHerdCommentsRef.current.value = comments;
        setAMChecked(is_association_member);
        setPromptChecked(prompt_tank_weights);
        setDhiaChargeChecked(dhiaCharge);
        editHerdWeightFactorRef.current.value = weight_factor;
        editAddr1Ref.current.value = addr1;
        editAddr2Ref.current.value = addr2;
        setStateIndex(state_index);
        editCityRef.current.value = city;
        editZipcodeRef.current.value = zipcode;

        setEditHerdVisible(true);

        setTimeout(() => {
            editUSDAIDRef.current.value = usdaID;
        }, 200)
    }

    const showStatsModal = (index) => {
        const curr_cust = customers.find(cust => cust.id === selectedID);
        const curr_herd = curr_cust.herds[index];
        getRecentResults(curr_herd.id);
        setStatsVisible(true);
    }

    const showNewHerdModal = () => {
        setNewHerdVisible(true);
    }

    const showNewRILModal = () => {
        setNewRILVisible(true);
    }

    const showEditRILModal = (id) => {
        setEditRILVisible(true);
        const ril = customers.find(cust => cust.id === selectedID).recurring_invoice_line_items.find(ril => ril.id === id)
        editRILAmountRef.current.value = ril.rate
        setSelectedRILID(ril.id);
        setCategoryIndex(categories.findIndex(c => c.id === ril.category.id));
    }

    // Should hide any modal and reset their state
    const hideModal = () => {
        setEditCustVisible(false);
        setNewCustVisible(false);
        setEditHerdVisible(false);
        setNewHerdVisible(false);
        setNewRILVisible(false);
        setEditRILVisible(false);
        setSameBillingAddr(true);
        setStatsVisible(false);
        setEditModalVisible(false);
        setNewModalVisible(false);

        newNameRef.current.value = "";
        newEmailRef.current.value = "";
        newBRRef.current.value = "1.00";
        newAddr1Ref.current.value = "";
        newAddr2Ref.current.value = "";
        newCityRef.current.value = "";
        newZipcodeRef.current.value = "";
        newMobileNumRef.current.value = "";

        newHerdNameRef.current.value = "";
        newFilecodeRef.current.value = "";
        newHerdMilkingsRef.current.value = "0";
        newHerdCommentsRef.current.value = "";
        newHerdWeightFactorRef.current.value = "1.0"

        newRILAmountRef.current.value = "";

        newContactNameRef.current.value = "";
        newContactEmailRef.current.value = "";
        newContactAddr1Ref.current.value = "";
        newContactAddr2Ref.current.value = "";
        newContactCityRef.current.value = "";
        newContactZipcodeRef.current.value = "";
        newContactMobileNumRef.current.value = "";
        setStateIndex(0);

        setAMChecked(false);
        setOfficialChecked(false);
        setPromptChecked(false);
        setDhiaChargeChecked(false);
        setModalError("");

    }

    const validateContact = (name, email) => {
        if (name === "") {
            setModalError("Name cannot be empty");
            return false;
        } else if (email === "") {
            setModalError("Email cannot be empty");
            return false;
        }

        return true;
    }

    const openNewHerdModal = (index) => {
        setHerdIndex(index);
        setNewModalVisible(true);
    }

    const openNewGlobalModal = () => {
        setHerdIndex(-1);
        setNewModalVisible(true);
    }

    const openEditHerdModal = (herd_index, contact_index) => {
        const herd = customers[selectedId].herds[herd_index];
        const c = herd.contacts[contact_index];
        editContactNameRef.current.value = c.name;
        editContactEmailRef.current.value = c.email;
        editContactMobileNumRef.current.value = c.mobile_num;
        editContactAddr1Ref.current.value = c.addr1;
        editContactAddr2Ref.current.value = c.addr2;
        editContactCityRef.current.value = c.city;
        setStateIndex(c.state? state_options.findIndex(e => e.abbreviation === c.state): 0);
        editContactZipcodeRef.current.value = c.zipcode;

        setHerdIndex(herd_index);
        setHerdContactIndex(contact_index);
        setEditModalVisible(true);
    }

    const openEditGlobalModal = (index) => {
        let c
        if (index === -1) {
            c = customers[selectedId].contact;
        } else {
            c = customers[selectedId].contacts[index];
        }

        editContactNameRef.current.value = c.name;
        editContactEmailRef.current.value = c.email;
        editContactMobileNumRef.current.value = c.mobile_num;
        editContactAddr1Ref.current.value = c.addr1;
        editContactAddr2Ref.current.value = c.addr2;
        editContactCityRef.current.value = c.city;
        setStateIndex(c.state? state_options.findIndex(e => e.abbreviation === c.state): 0);
        editContactZipcodeRef.current.value = c.zipcode;

        setHerdIndex(-1);
        setGlobalContactIndex(index);
        setEditModalVisible(true);
    }

    // Input validation
    const validateEditCustomer = (
        name,
        email,
        billing_rate,
        mobile_num
    ) => {

        if (name === "") {
            setModalError("Name cannot be empty");
        }
        else if (billing_rate === "") {
            setModalError("Billing Rate cannot be empty");
        }
        else if (!checkEmail(email)) {
            setModalError("Must use a valid email address");
        }
        else if (mobile_num !== "" && !/[0-9]./g.test(mobile_num)) {
            setModalError("Mobile Number can only contain numbers");
        }
        else {
            return true;
        }

        return false;
    }

    const validateEditherd = (name, milkings, usdaID, filecode) => {
        if (name === "") {
            setModalError("Name cannot be empty");
            return false;
        }
        else if (milkings === "") {
            setModalError("Milkings Per Day cannot be empty");
            return false;
        } else if(officialChecked && usdaID.length !== 8) {
            setModalError("A Valid USDA ID is required");
            return false;
        } else if (officialChecked && pcIndex===0) {
            setModalError("Please Select a Processing Center");
            return false;
        } else if (filecode === "") {
            setModalError("Filecode cannot be empty");
            return false;
        }

        return true;
    }

    const validateRIL = (amount) => {
        if (amount === "") {
            setModalError("Amount cannot be empty");
            return false;
        }
        return true;
    }

    // Convert customer to table data
    const getTableData = () => {
        return customers
        .map((item, index) => [item, index])
        .filter(([item, _]) => {
            return (showObsolete || !item.obsolete);
        })
        .map(([item, index]) => {
            let herd_count = 0;
            item.herds.forEach((h) => {
                if (showObsolete || !h.obsolete) {
                    herd_count += 1;
                }
            });

            return [
                index,
                item.old_id,
                item.name,
                item.email,
                herd_count,
                '$ ' + item.billing_rate.toFixed(2)
            ];
        });
    }

    const scrollToTop = () => {
        scrollRef.current.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    const setObsoleteFilter = (val) => {
        if (!val && selectedID != null) {
            const cust = customers.find(c => c.id===selectedID);
            if (cust && cust.obsolete) {
                setSelectedID(null);
            }
        }

        setShowObsolete(val);
    }

    // If a customer is selected, populate the edit modal
    useEffect(() => {
        // Populate edit modal for selected customer
        const setEditItems = async () => {
            const cust = customers.find(cust => cust.id === selectedID);
            const cont = await api.getContact(cust.contact_id);
            setContact(cont);

            editNameRef.current.value = cust.name;
            editEmailRef.current.value = cust.email;
            editBRRef.current.value = cust.billing_rate.toFixed(2);
            editAddr1Ref.current.value = cont.addr1;
            editAddr2Ref.current.value = cont.addr2;
            editCityRef.current.value = cont.city;
            setStateIndex(states.findIndex((s) => {return s.abbreviation === cont.state}));
            editZipcodeRef.current.value = cont.zipcode;
            editMobileNumRef.current.value = cont.mobile_num;
        }
        if (selectedID !== null) {
            setEditItems();
        }
    }, [selectedID, customers, api])

    const curr_cust = customers.find(cust => cust.id === selectedID);
    const curr_herd = curr_cust? curr_cust.herds.find(herd => herd.id === selectedHerdID): undefined;

    // fetch the customer data
    useEffect(() => {
        getProcessingCenters();
        getRegions();
        getBreeds();
        getCustomers();
        getCategories();
    }, []);

    let owner;
    if (selectedId !== -1) {
        owner = customers[selectedId].contact
    }

    return (
        <div className="CustomerScreen">
            {api.render()}
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                <SearchBox text="Search Customers" width="50%" options={customers.map(cust => cust.old_id + " - " + cust.name)} onSelect={(index) => {
                    setSelectedID(customers[index]? customers[index].id: null);
                    setSelectedId(index);
                }}/>
                <div style={{width: "32px"}} />
                <Checkbox label="Show Obsolete" checked={showObsolete} setChecked={setObsoleteFilter} />
            </div>
            {
                selectedID !== null? <CustomerDetail 
                    customer={customers.find(cust => cust.id === selectedID)}
                    contact={contact}
                    editCustomer={showCustomerModal}
                    newHerd={showNewHerdModal}
                    editHerd={showHerdModal}
                    viewStats={showStatsModal}
                    newRIL={showNewRILModal}
                    editRIL={showEditRILModal}
                    showObsolete={showObsolete}
                />: <div/>
            }
        { selectedId===-1? null : <div>
            <h2>Owner Contact</h2>
            <div style={{height: 16}} />
            <ContactCard contact={owner} API={api} editContact={() => openEditGlobalModal(-1)} />

            <h2>Customer Contacts</h2>
            <p>Customer Contacts can receive test results for every herd.</p>
            <Button text="Add Customer Contact" onClick={openNewGlobalModal}/>
            <div style={{height: 16}} />
            {
                customers[selectedId].contacts.map((val, index) => {
                    return <ContactCard contact={val} key={index} API={api} editContact={() => {openEditGlobalModal(index);}}/>
                })
            }

            <h2>Herd Contacts</h2>
            <p>Herd Contacts can only receive test results for a single herd.</p>

            {
                customers[selectedId].herds
                    .map((val, herd_index) => {
                        return [val, herd_index];
                    })
                    .filter((val) => {
                        return !val[0].obsolete;
                    })
                    .map((val) => {
                    return (

                        <div key={val[1]}>
                            <h3 key={val[1]}>{val[0].name}</h3>
                            <Button text="Add Contact" onClick={() => {openNewHerdModal(val[1]);}}/>
                            <div style={{height: 16}} />
                            {
                                val[0].contacts.map((c, contact_index) => {
                                    return <ContactCard contact={c} key={contact_index} API={api} editContact={() => {openEditHerdModal(val[1], contact_index);}}/>
                                })
                            }
                        </div>
                    );
                })
            }
            </div>
        }
            <div style={{height: 48}} />
            <Button text="New Customer" onClick={showNewModal}/>
            <div style={{height: 48}} />
            <ClickableTable
                headers={headers}
                data={getTableData()}
                onClick={(index) => {
                    setSelectedID(customers[index].id);
                    setSelectedId(index);
                    scrollToTop();
                }}
                sortBy={-1}
            />
            <Modal
                visible={newCustVisible}
                title="New Customer"
                primaryText="Save"
                secondaryText="Cancel"
                onPrimary = {createCustomer}
                onSecondary={hideModal}
                error={modalError}
            >
                <TextInput placeholder="Name" innerRef={newNameRef}/>
                <TextInput placeholder="Email" innerRef={newEmailRef} />
                <CurrencyInput placeholder="Billing Rate" type="number" value="1.00" innerRef={newBRRef}/>
                <TextInput placeholder="Mobile Number (Optional)" innerRef={newMobileNumRef} />
                <p className="text" style={{fontSize: 20}}>Address</p>
                <TextInput placeholder="Address 1" innerRef={newAddr1Ref}/>
                <TextInput placeholder="Address 2 Optional" innerRef={newAddr2Ref}/>
                <TextInput placeholder="City" innerRef={newCityRef}/>
                <SelectInput placeholder="State" options={states} selectedIndex={stateIndex} onSelect={setStateIndex}/>
                <TextInput placeholder="Zipcode" innerRef={newZipcodeRef}/>
            </Modal>
            <Modal
                visible={editCustVisible}
                title="Edit Customer"
                primaryText="Save"
                secondaryText="Cancel"
                ternaryText={curr_cust && curr_cust.obsolete? "Restore": "Mark Obsolete"}
                onPrimary={updateCustomer}
                onSecondary={hideModal}
                onTernary={deleteCustomer}
                error={modalError}
            >
                <TextInput
                    placeholder="Name"
                    innerRef={editNameRef}
                />
                <TextInput
                    placeholder="Email"
                    innerRef={editEmailRef}
                />
                <CurrencyInput
                    placeholder="Billing Rate"
                    innerRef={editBRRef}
                />
                <TextInput placeholder="Mobile Number (Optional)" innerRef={editMobileNumRef} />
            </Modal>
            <Modal
                visible={newHerdVisible}
                title="New Herd"
                primaryText="Save"
                secondaryText="Cancel"
                onPrimary={createHerd}
                onSecondary={hideModal}
                error={modalError}
            >
                <TextInput placeholder="Name" innerRef={newHerdNameRef}/>
                <TextInput placeholder="Filecode" innerRef={newFilecodeRef} />
                <SelectInput placeholder="Region" options={regions} selectedIndex={regionIndex} onSelect={setRegionIndex}/>
                <SelectInput placeholder="Breed" options={breeds} selectedIndex={breedIndex} onSelect={setBreedIndex}/>
                <SelectInput placeholder="Processing Center" options={processingCenters} selectedIndex={pcIndex} onSelect={setPcIndex}/>
                <Checkbox label="Association Member" checked={amChecked} setChecked={setAMChecked} />
                <Checkbox label="Official" checked={officialChecked} setChecked={setOfficialChecked} />
                <Checkbox label="Prompt for Tank Weights" checked={promptChecked} setChecked={setPromptChecked} />
                <Checkbox label="DHIA Charge" checked={dhiaChargeChecked} setChecked={setDhiaChargeChecked} />
                {
                    officialChecked? <TextInput type="number" placeholder="USDA ID" innerrRef={newUSDAIDRef}/>: null
                }
                <Checkbox label="Billing Address same as Customer" checked={sameBillingAddr} setChecked={setSameBillingAddr} />

                {
                    sameBillingAddr? null: <div>
                        <TextInput placeholder="Billing Address 1" innerRef={editAddr1Ref}/>
                        <TextInput placeholder="Billing Address 2 (Optional)" innerRef={editAddr2Ref}/>
                        <TextInput placeholder="Billing City" innerRef={editCityRef}/>
                        <SelectInput placeholder="Billing State" options={states} selectedIndex={stateIndex} onSelect={setStateIndex}/>
                        <TextInput placeholder="Billing Zipcode" innerRef={editZipcodeRef}/>
                    </div>
                }

                <div style={{height: 8}} />
                <TextInput placeholder="Milkings Per Day" innerRef={newHerdMilkingsRef} type="number" value="0"/>
                <CurrencyInput placeholder="Weight Factor" innerRef={newHerdWeightFactorRef} value="1.0"/>
                <p>Comments</p>
                <Textarea rows={5} innerRef={newHerdCommentsRef}/>
            </Modal>
            <Modal
                visible={editHerdVisible}
                title="Edit Herd"
                primaryText="Save"
                secondaryText="Cancel"
                ternaryText={curr_herd && curr_herd.obsolete? "Restore": "Mark Obsolete"}
                onPrimary={updateHerd}
                onSecondary={hideModal}
                onTernary={deleteHerd}
                error={modalError}
            >
                <TextInput placeholder="Name" innerRef={editHerdNameRef}/>
                <TextInput placeholder="Filecode" innerRef={editFilecodeRef} />
                <SelectInput placeholder="Region" options={regions} selectedIndex={regionIndex} onSelect={setRegionIndex}/>
                <SelectInput placeholder="Breed" options={breeds} selectedIndex={breedIndex} onSelect={setBreedIndex}/>
                <SelectInput placeholder="Processing Center" options={processingCenters} selectedIndex={pcIndex} onSelect={setPcIndex}/>
                <Checkbox label="Association Member" checked={amChecked} setChecked={setAMChecked}/>
                <Checkbox label="Official" checked={officialChecked} setChecked={setOfficialChecked}/>
                <Checkbox label="DHIA Charge" checked={dhiaChargeChecked} setChecked={setDhiaChargeChecked} />
                <Checkbox label="Prompt for Tank Weights" checked={promptChecked} setChecked={setPromptChecked} />
                <TextInput type="number" placeholder="USDA ID" innerRef={editUSDAIDRef}/>
                <TextInput placeholder="Billing Address 1" innerRef={editAddr1Ref}/>
                <TextInput placeholder="Billing Address 2 (Optional)" innerRef={editAddr2Ref}/>
                <TextInput placeholder="Billing City" innerRef={editCityRef}/>
                <SelectInput placeholder="Billing State" options={states} selectedIndex={stateIndex} onSelect={setStateIndex}/>
                <TextInput placeholder="Billing Zipcode" innerRef={editZipcodeRef}/>
                <div style={{height: 8}} />
                <TextInput placeholder="Milkings Per Day" innerRef={editHerdMilkingsRef} type="number" value="0"/>
                <CurrencyInput placeholder="Weight Factor" innerRef={editHerdWeightFactorRef} value="1.0"/>
                <p>Comments</p>
                <Textarea rows={5} innerRef={editHerdCommentsRef}/>
            </Modal>
            <Modal
                title="New Recurring Line Item"
                visible={newRILVisible}
                error={modalError}
                primaryText="Save"
                onPrimary={newRIL}
                secondaryText="Cancel"
                onSecondary={hideModal}
            >
                <SelectInput options={categories} selectedIndex={categoryIndex} onSelect={setCategoryIndex}/>
                <CurrencyInput placeholder="Amount" innerRef={newRILAmountRef}/>
            </Modal>
            <Modal
                title="Edit Recurring Line Item"
                visible={editRILVisible}
                error={modalError}
                primaryText="Save"
                onPrimary={updateRIL}
                secondaryText="Cancel"
                onSecondary={hideModal}
                ternaryText="Delete"
                onTernary={deleteRIL}
            >
                <SelectInput options={categories} selectedIndex={categoryIndex} onSelect={setCategoryIndex}/>
                <CurrencyInput placeholder="Amount" innerRef={editRILAmountRef}/>
            </Modal>
            <Modal
                title="Herd Stats"
                visible={statsVisible}
                primaryText="Close"
                onPrimary={hideModal}
            >
        {recentResults.length === 0? <p style={{whitespace: 'nowrap'}}>No stats available</p>: <table className="verifyTable">
            <tr>
                <th>Date</th>
                <th># Cows</th>
                <th>MilkWeight</th>
                <th>Butterfat</th>
                <th>Protein</th>
                <th>SCC</th>
            </tr>
            {
                recentResults.map(res => {
                    const date = new Date(res.time_uploaded);
                    return (<tr>
                        <td>{date.getMonth()+1}/{date.getDate()}</td>
                        <td>{res.num_cows} Cows</td>
                        <td>{res.milk_weight.toFixed(2)} lb</td>
                        <td>{res.butterfat.toFixed(2)} %</td>
                        <td>{res.protein.toFixed(2)} %</td>
                        <td>{res.scc.toFixed(0)} Cells</td>
                    </tr>);
                })
            }
        </table>
        }
            </Modal>
        <Modal
                title="New Contact"
                visible={newModalVisible}
                primaryText="Save"
                onPrimary={addContact}
                secondaryText="Cancel"
                onSecondary={hideModal}
                error={modalError}
            >
                <TextInput
                    placeholder="Name"
                    innerRef={newContactNameRef}
                />
                <TextInput
                    placeholder="Email"
                    innerRef={newContactEmailRef}
                />
                <TextInput placeholder="Mobile Number (Optional)" innerRef={newContactMobileNumRef} />
                <p>Address</p>
                <TextInput placeholder="Address 1 (Optional)" innerRef={newContactAddr1Ref}/>
                <TextInput placeholder="Address 2 (Optional)" innerRef={newContactAddr2Ref}/>
                <TextInput placeholder="City (Optional)" innerRef={newContactCityRef}/>
                <SelectInput placeholder="State (Optional)" options={state_options} selectedIndex={stateIndex} onSelect={setStateIndex}/>
                <TextInput placeholder="Zipcode (Optional)" innerRef={newContactZipcodeRef}/>
            </Modal>
            <Modal
                title="Edit Contact"
                visible={editModalVisible}
                primaryText="Save"
                onPrimary={editContact}
                secondaryText="Cancel"
                onSecondary={hideModal}
                ternaryText="Delete"
                onTernary={deleteContact}
                error={modalError}
            >
                <TextInput
                    placeholder="Name"
                    innerRef={editContactNameRef}
                />
                <TextInput
                    placeholder="Email"
                    innerRef={editContactEmailRef}
                />
                <TextInput placeholder="Mobile Number (Optional)" innerRef={editContactMobileNumRef} />
                <p>Address</p>
                <TextInput placeholder="Address 1 (Optional)" innerRef={editContactAddr1Ref}/>
                <TextInput placeholder="Address 2 (Optional)" innerRef={editContactAddr2Ref}/>
                <TextInput placeholder="City (Optional)" innerRef={editContactCityRef}/>
                <SelectInput placeholder="State (Optional)" options={state_options} selectedIndex={stateIndex} onSelect={setStateIndex}/>
                <TextInput placeholder="Zipcode (Optional)" innerRef={editContactZipcodeRef}/>
            </Modal>
        </div>
    );
}

export default Customers;
