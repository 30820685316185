import '../styles/login.scss';

import {useState, useRef} from 'react';

import Button from '../components/Button';
import TextInput from '../components/TextInput';
import {ReactComponent as Logo} from '../assets/logo.svg';
import {checkEmail} from '../util';

const EmailForm = (props) => {
    const [error, setError] = useState("");
    const [sentRequest, setSentRequest] = useState(false);

    const nameRef = useRef(null);
    const emailRef = useRef(null);

    const api: API = props.API;

    // Validate the user input
    const validate = (name, email) => {
        if (name==="") {
            setError("Name cannot be empty");
            return false;
        } else if (email === "") {
            setError("Email cannot be empty");
            return false;
        } else if (!checkEmail(email)) {
            setError("Email not valid");
            return false;
        }

        return true;
    }

    const sendRequest = async () => {
        const name = nameRef.current.value;
        const email = emailRef.current.value;

        if (validate(name, email)) {
            await api.sendRequest(name, email);
            setSentRequest(true);
        }
    }

    return (
        <div className="LoginScreen">
            <div className="LoginHeader">
                <Logo />
                <p>High Desert Dairy</p>
            </div>
                {sentRequest? <div className="LoginCard"><p>Request Sent, you should hear back from us shortly.</p></div>:
            <div className="LoginCard">
                <p>Request an Account</p>
                <form>
                <TextInput placeholder="Name" innerRef={nameRef}/>
                <TextInput placeholder="Email" type="email" autocomplete="email" innerRef={emailRef}/>
                    <p className="Error">{error}</p>
                    <div className="Buttons">
                        <Button text="Save" onClick={sendRequest} submit={true}/>
                    </div>
                </form>
            </div>}
        </div>
    );
}



export default EmailForm;
