import '../styles/SelectInput.scss';
import {useState} from 'react';
import {ReactComponent as ArrowIcon} from '../assets/down_arrow.svg';

const SelectInput = (props) => {
    
    const placeholder = props.placeholder;
    const innerRef = props.innerRef;
    const options = props.options? props.options: [];
    const def = props.default? props.default: "";

    const selectedIndex = props.selectedIndex;
    const setSelectedIndex = props.onSelect;
    const [showOptions, setShowOptions] = useState(false);
    const className = props.className? props.className: "";

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    }

    return (
        <div className={"SelectInput " + className}>
            <div
                id="selected"
                tabIndex="1"
                onClick={toggleOptions}
                onBlur={() => {setShowOptions(false)}}
                ref={innerRef}
            >
                <p>{options[selectedIndex]? options[selectedIndex].name: def}</p>
                <ArrowIcon className={showOptions? "rotated": ""}/>
                <div id="options" className={showOptions? "": "hidden"}>
                    {
                        options.map((val, index) => {
                            return <div key={index} onClick={() => {setSelectedIndex(index); toggleOptions();}}>{val.name}</div>;
                        })
                    }
                </div>
            </div>
            <label>{placeholder}</label>
        </div>
    );
}

export default SelectInput;
