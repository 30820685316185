import '../styles/login.scss';

import {useState, useRef} from 'react';

import Button from '../components/Button';
import TextInput from '../components/TextInput';
import {ReactComponent as Logo} from '../assets/logo.svg';
import {checkEmail} from '../util';
import {Redirect} from 'react-router-dom';

const EmailForm = (props) => {
    const [error, setError] = useState("");
    const [emailSet, setEmailSet] = useState(false);

    const emailRef = useRef(null);
    const api: API = props.API;

    // Validate the user input
    const validate = (email) => {
        if (email === "") {
            setError("Email cannot be empty");
            return false;
        } else if (!checkEmail(email)) {
            setError("Email not valid");
            return false;
        }

        return true;
    }

    // Set the email
    const setEmail = async () => {
        const email = emailRef.current.value;

        if (validate(email)) {
            let success = await api.updateEmail(email);
            if (success) {
                api.requireEmail = false;
                setEmailSet(true);
            } else {
                setError("Could not update Email");
            }
        }
    }

    return (
        <div className="LoginScreen">
            {!api.loggedIn? api.render(): null}
            {emailSet? <Redirect to={{pathname: "/cloud"}} /> : null}
            <div className="LoginHeader">
                <Logo />
                <p>High Desert Dairy</p>
            </div>
            <div className="LoginCard">
                <p>Enter Email</p>
                <form>
                <TextInput placeholder="Email" type="email" autocomplete="email" innerRef={emailRef}/>
                    <p className="Error">{error}</p>
                    <div className="Buttons">
                        <Button text="Save" onClick={setEmail} submit={true}/>
                    </div>
                </form>
            </div>
        </div>
    );
}



export default EmailForm;
