import '../styles/SearchBox.scss';

import {ReactComponent as SearchIcon} from '../assets/search-24px.svg';
import {ReactComponent as CloseIcon} from '../assets/close-black-18dp.svg';
import { useState, useRef, useEffect } from 'react';
import fuzzysort from 'fuzzysort';

const SearchBox = (props) => {

    const [focused, setFocused] = useState(false);
    const [available, setAvailable] = useState([]);
    const inputRef = useRef(null);
    const [selected, setSelected] = useState(null);

    const className = "SearchBox" + (focused? " selected": "");
    const options = props.options? props.options: [];
    const onSelect = props.onSelect? props.onSelect: () => {};

    const onKeyDown = () => {
        const filter = inputRef.current.value;
        const results = fuzzysort.go(filter, options, {all: true});
        setAvailable(results);
    }

    const onItemSelect = (index) => {
        setSelected(options[index]);
        setAvailable([]);
        onSelect(index);
    }

    const clearSelection = () => {
        inputRef.current.value = ""
        onSelect(-1);
        setSelected(null);
    }

    useEffect(() => {
        if (props.selected!==undefined && props.selected !== -1) {
            setSelected(options[props.selected]);
        }
    }, [props.selected]);

    return (
        <div className="SearchContainer">
            <div
                className={className}
                onClick={() => {inputRef.current.focus()}}
            >
                <SearchIcon />
                <input
                    type="text"
                    ref={inputRef}
                    placeholder={props.text}
                    onFocus={() => {setFocused(true);}}
                    onBlur={() => setTimeout(() => {
                        setFocused(false);
                        setAvailable([])
                        if (inputRef.current) {
                            inputRef.current.value="";
                        }
                    }, 200)}
                    onKeyDown={onKeyDown}
                />
                <div className={selected !== null? "": "hidden"} onClick={clearSelection}>
                    <p>{selected}</p>
                    <CloseIcon />
                </div>
            </div>
            {
                available.length > 0 && focused? <div className="SearchResults">
                {
                    available.map((val, i) => {
                        console.log(val)
                        return <div className="Item" key={i} onClick={() => {
                            onItemSelect(options.indexOf(val.target))}
                        } dangerouslySetInnerHTML={{__html: val.target}} />
                    })
                }
            </div>: null
            }
        </div>
    );
}

export default SearchBox;
