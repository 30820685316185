import '../styles/TextInput.scss';

const DateInput = (props) => {

    const placeholder = props.placeholder? props.placeholder: "";
    const ref = props.innerRef;
    const value = props.value? props.value: "";

    return (
        <div className="TextInput">
            <input ref={ref} required={true} defaultValue={value} type="date"/>
            <label>{placeholder}</label>
        </div>
    );
}

export default DateInput;