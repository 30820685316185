import '../styles/Landing.scss';
import {ReactComponent as Logo} from '../assets/logo.svg';
import Button from '../components/Button';
import {useHistory} from 'react-router-dom';

const LandingPage = () => {
    const history = useHistory();

    return (
        <div className="LandingScreen">
            <div className="Content">
                <div className="Header">
                    <div>
                    <Logo />
                    <p>High Desert Dairy Lab, Inc.</p>
                    </div>
                    <div>
                        <Button text="Login to Cloud" onClick={() => {history.push("/cloud")}}/>
                    </div>
                </div>

                <div className="slogan">
                    <h1>High Desert Dairy Lab, Inc.</h1>
                    <h2>Same Day Processing.</h2>
                    <h2>Fair Rates.</h2>
                    <h2>Accurate Results.</h2>
                </div>

                <div className="section">
                    <h3>What We Do</h3>
                    <p>The dairy industry has changed a great deal since we began testing milk back in 1980.  Bigger herds and more technology have taken over the dairy landscape and we’ve kept up stride for stride. When we started testing we averaged 15,000 samples a month. Now our monthly total is over 400,000 samples.</p>
                    <p>High Desert Dairy Labs utilizes fully automated, high speed, state of the art test equipment for fast, accurate results. Test results can be delivered in a variety of formats and methods. Testing is provided for Somatic Cell Count, Fat, Lactose, Protein, and MUN.</p>
                </div>
            </div>
            <div className="Footer">
                <p><a href="/privacy">Privacy Policy</a></p>
                <p>© {new Date().getFullYear()}, High Desert Dairy Lab, Inc. All Rights Reserved</p>
            </div>
        </div>
    );
}

export default LandingPage;
