import Button from "../components/Button";
import {useState, useRef, useEffect} from 'react';
import SelectInput from "../components/SelectInput";
import API from '../util/AdminAPI'
import '../styles/AdminScreen.scss';
import Modal from '../components/Modal';


const DryMatterScreen = (props) => {
    const api: API = props.API;

    const [error, setError] = useState("");
    const [customers, setCustomers] = useState([]);
    const [feeds, setFeeds] = useState([]);
    const selectedId = props.dmCust;
    const setSelectedId = props.setDmCust;

    const samples = props.dryMatter;
    const setSamples = props.setDryMatter;


    const [verifyBatchId, setVerifyBatchId] = useState(null);
    const [verifyBatch, setVerifyBatch] = useState([]);

    const getCustomers = async () => {
        let custs = await api.getDryMatterCustomers();
        setCustomers(custs);
    }

    const getFeedTypes = async () => {
        setFeeds(await api.getFeedTypes());
    }

    const newBlankSample = () => {
        let l = samples[samples.length-1];

        // propogate farm, field, feed type, date, truck, and residual

        let tnum = parseInt(l[7])+1;
        
        let s = samples.slice();
        s.push([l[0], l[1], l[2], "", l[4], l[5], l[6], tnum.toString(), "", "", l[10]])
        setSamples(s)
    }

    const complete = (s) => {
        // if last sample is not blank, add a new blank sample at the end
        return s[0] !== ""
            && s[5] !== ""
            && s[7] !== ""
            && s[8] !== ""
            && s[9] !== ""
            && s[10] !== "";
    }

    if (complete(samples[samples.length-1])) {
        newBlankSample();
    }

    useEffect(() => {
        getCustomers();
        getFeedTypes();
    }, []);

    const getSampleObjs = () => {
        let sams = [];

        for (let i=0; i<samples.length-1; i++) {
            let r = samples[i];

            // if this row is completely empty just continue
            if (!samples[i].some((e, j) => e !== "" && j !== 4)) {
                continue;
            }

            // check required fields
            if (r[0] === "") {
                setError("Farm is required");
                return undefined;
            }

            if (r[5] === "") {
                setError("Date received is required");
                return undefined;
            }

            if (r[7] === "") {
                setError("Tray number is required");
                return undefined;
            }

            if (r[8] === "") {
                setError("Wet Weight is required");
                return undefined;
            }

            if (r[9] === "") {
                setError("Dry Weight is required");
                return undefined;
            }

            if (r[10] === "") {
                setError("Residual % is required");
                return undefined;
            }

            // now create the obj
            let a = {
                farm: r[0],
                field: r[1],
                truck: r[2],
                load: r[3] === ""? undefined : r[3],
                feed_type_id: feeds[r[4]].id,
                date_received: r[5],
                time_received: r[6] === ""? undefined : r[6],
                tray_id: r[7],
                wet_weight: r[8],
                dry_weight: r[9],
                residual: r[10]
            };

            sams.push(a);
        }

        return sams;
    }

    const uploadSamples = async () => {
        setError("");
        let s = getSampleObjs();

        if (s === undefined) { return }

        if (s.length === 0) {
            return;
        }

        if (selectedId === -1) {
            setError("Must select a customer");
            return;
        }

        let res = await api.uploadDrymatterSamples(customers[selectedId].id, s);

        if (res.error) {
            setError(res.error);
        } else {
            setVerifyBatchId(res.batch_id)
            setVerifyBatch(JSON.parse(res.batch))
        }
    }

    const verifySamples = async () => {
        await api.verifyDrymatterSamples(verifyBatchId);
        setSelectedId(-1);
        setSamples([["", "", "", "", 0, "", "", "", "", "", ""]])
        setVerifyBatch([]);
        setVerifyBatchId(null);
    }

    return (
        <div className="DryMatterScreen" style={{margin: 32}}>
            {api.render()}
            <SelectInput
                placeholder="Customer"
                default="Select a Customer"
                options={customers}
                selectedIndex={selectedId}
                onSelect={setSelectedId}
            />
            <table>
                <thead>
                <tr>
                    <th>Farm</th>
                    <th>Field</th>
                    <th>Truck</th>
                    <th>Load</th>
                    <th>Feed Type</th>
                    <th>Date Received</th>
                    <th>Time Received</th>
                    <th>Tray Number</th>
                    <th>Wet Weight</th>
                    <th>Dry Weight</th>
                    <th>Residual %</th>
                </tr>
                </thead>
                <tbody>
                {samples.map((e, i) => {
                    return <tr>
                        {e.map((f, j) => {
                            let type = "text";

                            if ([3, 7, 8, 9, 10].includes(j)) {
                                type = "number"
                            }

                            if (j === 5) {
                                type = "date";
                            }

                            if (j === 6) {
                                type = "time";
                            }

                            if (j === 4) {
                                return <td>
                                    <select value={f} onChange={(v) => {
                                        let s = samples.slice();
                                        s[i][j] = v.target.value;
                                        setSamples(s);
                                    }}>
                                    {feeds.map((e, i) => {
                                        return <option value={i}>{e.name}</option>;
                                    })}
                                    </select>
                                </td>;
                            }

                            return <td>
                                <input
                                    style={{width: 130}}
                                    value={f}
                                    onChange={(v) => {
                                        let s = samples.slice();
                                        s[i][j] = v.target.value;
                                        setSamples(s);
                                    }}
                                    type={type}
                                />
                            </td>
                        })}
                    </tr>;
                })}
                </tbody>
            </table>
            <br />
            <br />
            <Button text="Upload" onClick={uploadSamples}/>
            <p>{error}</p>
            <Modal
                visible = {verifyBatchId !== null}
                primaryText = "Verify"
                onPrimary = {verifySamples}
                secondaryText = "Cancel"
                onSecondary = { () => setVerifyBatchId(null) }
            >
                <table>
                    <tr style={{whitespace: 'nowrap'}}>
                        <th>Farm</th>
                        <th>Field</th>
                        <th>Truck</th>
                        <th>Load</th>
                        <th style={{whiteSpace: 'nowrap'}}>Feed Type</th>
                        <th style={{whiteSpace: 'nowrap'}}>Date Received</th>
                        <th style={{whiteSpace: 'nowrap'}}>Moisture %</th>
                        <th style={{whiteSpace: 'nowrap'}}>Dry %</th>
                    </tr>
                { verifyBatch.map((e) => {
                    return <tr>
                        <td>{e["Farm"]}</td>
                        <td>{e["Field"]}</td>
                        <td>{e["Truck"]}</td>
                        <td>{e["Load"]}</td>
                        <td>{e["Feed Type"]}</td>
                        <td>{e["Date Received"]}</td>
                        <td>{e["Moisture %"]}</td>
                        <td>{e["Dry %"]}</td>
                    </tr>;
                }) }
                </table>
            </Modal>
        </div>
    )
}
 
export default DryMatterScreen;
