import '../styles/login.scss';

import {useState, useEffect, useRef} from 'react';
import {checkEmail} from '../util'

import Button from '../components/Button';
import TextInput from '../components/TextInput';
import {ReactComponent as Logo} from '../assets/logo.svg';
import {Link, Redirect} from 'react-router-dom';

const RequestReset = (props) => {
    const [error, setError] = useState("");
    const [requestSent, setRequestSent] = useState("");

    const emailRef = useRef(null);
    
    const api = props.API;

    // Validate the user input
    const validate = (email) => {
        if (!email) {
            setError("Email cannot be empty");
        } else if (!checkEmail(email)){
            setError("Invalid Email");
        }

        return true;
    }

    // Reset the password
    const send_email = () => {
        const email = emailRef.current.value;

        if (validate(email)) {
            api.sendReset(email);
            setRequestSent(true);
        }
    }

    return (
        <div className="LoginScreen">
            <div className="LoginHeader">
                <Logo />
                <p>High Desert Dairy</p>
            </div>
            <div className="LoginCard">
                <p>Reset Password</p>
                {requestSent? <p style={{fontSize: "1em"}}>Check Your Email for a Link</p>:
                <form>
                    <TextInput placeholder="Email" autocomplete="username" innerRef={emailRef}/>
                    <p className="Error">{error}</p>
                    <div className="Buttons">
                        <Button text="Reset Password" onClick={send_email} submit={true}/>
                    </div>
                </form> 
                }
            </div>
        </div>
    );
}



export default RequestReset;