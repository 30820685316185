import '../styles/EditableList.scss';
import {useState} from 'react';
import {ReactComponent as AddLogo} from '../assets/add_box-24px.svg';
import Modal from './Modal';


const EditableList = (props) => {

    const items = props.items;
    const title = props.title;
    const deletable = props.deletable? props.deletable: true;

    const newItem = props.newItem? props.newItem: () => {};
    const editItem = props.editItem? props.editItem: () => {};
    const deleteItem = props.deleteItem? props.deleteItem: () => {};
    const validate = props.validate? props.validate: () => "";
    const populate = props.populate? props.populate: () => "";

    const [visible, setVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedAction, setSelectedAction] = useState();
    const [modalError, setModalError] = useState("");

    const hideModal =() => {
        setVisible(false);
    }

    const onNew = () => {
        const error = validate();
        if (error !== "") {
            setModalError(error);
        } else {
            newItem();
            hideModal();
        }
    }

    const onEdit = () => {
        const error = validate();
        if (error !== "") {
            setModalError(error);
        } else {
            editItem(selectedIndex);
            hideModal();
        }
    }

    const onPrimary = () => {
        switch(selectedAction) {
            case "New":
                onNew();
                break;
            case "Edit":
                onEdit();
                break;
            default:
                break;
        }
    }

    const onDelete = () => {
        deleteItem(selectedIndex);
        hideModal();
    }

    const editModal = (index) => {
        populate(index);
        setSelectedIndex(index);
        setSelectedAction("Edit");
        setVisible(true);
    }

    const newModal = () => {
        populate(-1);
        setSelectedAction("New");
        setVisible(true);
    }

    return (
        <div className="EditableList">
            <div className="header">
                <div className="Title">{title}</div>
                <AddLogo onClick={newModal}/>
            </div>
            <div className="items">
                {
                    items.map((val, index) => {
                        return <div key={val.id} onClick={() => {editModal(index);}}>{val.name}</div>;
                    })
                }
            </div>

            <Modal
                title={selectedAction + " " + title}
                visible={visible}
                primaryText="Save"
                onPrimary={onPrimary}
                secondaryText="Cancel"
                onSecondary={hideModal}
                ternaryText={selectedAction === "Edit" && deletable? "Delete": ""}
                onTernary={onDelete}
                error={modalError}
            >
                {props.children}
            </Modal>
        </div>
    )
}


export default EditableList;