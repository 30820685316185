import {useRef, useState, useEffect} from 'react';

import Button from '../components/Button';
import Modal from '../components/Modal';
import FilesUpload from '../components/FilesUpload';
import ClickableTable from '../components/ClickableTable';
import CurrencyInput from '../components/CurrencyInput';
import TextInput from '../components/TextInput';
import DateInput from '../components/DateInput';
import TimeInput from '../components/TimeInput';
import Textarea from '../components/Textarea';
import SearchBox from '../components/SearchBox';
import CheckBox from '../components/Checkbox';

const Diff = (props) => {
    const a = props.a;
    const b = props.b;

    const diff = b-a;

    return (
        <p className={"diff " + (diff<0?"neg":"pos")}>
            {diff<0? '-' : '+'}{diff.toFixed(2)} {props.prefix}
        </p>
    );
}

const DataEntry = (props) => {
    const api = props.API;

    const [key, setKey] = useState(1);
    const [herds, setHerds] = useState([]);
    const [error, setError] = useState("");
    const [recentResults, setRecentResults] = useState([]);
    const [uploadVisible, setUploadVisible] = useState(false);
    const [detailVisible, setDetailVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [files, setFiles] = useState({rpt: [], out: []})
    const [herdIndex, setHerdIndex] = useState(-1);
    const [manual, setManual] = useState(false);
    const [stage, setStage] = useState("upload");
    const [rptData, setRptData] = useState([]);
    const [manualVisible, setManualVisible] = useState(false);
    const [summary, setSummary] = useState({});
    const [recentHerdResults, setRecentHerdResults] = useState([]);
    const [force, setForce] = useState(false);
    const [includeWeight, setIncludeWeight] = useState(false);

    const tankWeightRef = useRef(null);
    const sampleDateRef = useRef(null);
    const receivedDateRef = useRef(null);

    const shiftStart1Ref = useRef(null);
    const shiftStart2Ref = useRef(null);
    const shiftStart3Ref = useRef(null);

    const shiftEnd1Ref = useRef(null);
    const shiftEnd2Ref = useRef(null);
    const shiftEnd3Ref = useRef(null);

    const weight1Ref = useRef(null);
    const weight2Ref = useRef(null);
    const weight3Ref = useRef(null);

    const milkings1Ref = useRef(null);
    const milkings2Ref = useRef(null);
    const milkings3Ref = useRef(null);

    const pickupDate1Ref = useRef(null);
    const pickupDate2Ref = useRef(null);
    const pickupDate3Ref = useRef(null);

    const notesRef = useRef(null);

    useEffect(() => {
        const onError = (msg) => {
            setError(msg);
        }

        api.addListener(onError);

        return () => {
            api.removeListener(onError);
        }
    }, [api]);


    const getHerds = async () => {
        const hs = await api.getActiveHerds();
        setHerds(hs.map((val) => {
            val.name = val.customer_name + " | " + val.name;
            return val;
        }));
    }

    const getRecentResults = async () => {
        setRecentResults(await api.getRecentResults(10));
    }


    useEffect(() => {
        getRecentResults();
        getHerds();
    }, []);

    const hideModal = async () => {
        setUploadVisible(false);
        setDetailVisible(false);
        setManualVisible(false);
        setForce(false);
        setFiles({rpt: [], out: []})
        setKey(key+1);
        setRptData([]);
        setError("");
        setIncludeWeight(false);

        if (stage === "upload") {
            if (tankWeightRef.current) {
                tankWeightRef.current.value = "";
            }
        } else if (stage === "verify") {
            await getRecentResults();
        } else if (stage === "pc") {
            await getRecentResults();
        }

        setStage("upload");
    }

    const cancelVerify = async () => {
        await api.deleteResult(summary.id);
        hideModal();
    }

    const validate = (files) => {
        if (!manual && files.rpt.length !== files.out.length) {
            setError("Different number of OUT and RPT files. Manual ordering required.");
            return false;
        }

        if (herdIndex === -1) {
            setError("Must Select a Herd");
            return false;
        }

        if (includeWeight) {
            const weight = tankWeightRef.current.value;

            if (weight === "") {
                setError("Tank Weight is required");
                return false;
            } else if(isNaN(weight)) {
                setError("Tank Weight must be a number");
                return false;
            }
        }

        return true;
    }
    
    const uploadResult = async () => {
        if (herdIndex===-1) {
            setError("Must select a Herd");
            return;
        }

        const herd_id = herds[herdIndex].id;

        if (validate(files)) {
            let json
            if (includeWeight) {
                const tank_weight = tankWeightRef.current.value;
                json = await api.uploadResultTank(herd_id, files, manual, tank_weight, force);
            } else {
                json = await api.uploadResult(herd_id, files, manual, force);
            }

            if (json.error) {
                setError(json.error);
            } else {
                setSummary(json);
                setError("");
                setStage("verify");
            }
        }
    }

    const verify = async () => {
        await api.verifyData(summary.id);

        if (requiresPC()) {
            setStage("pc");
        } else {
            hideModal();
        }
    }

    const getTableData = () => {
        return recentResults.map((result, index) => {
            const herd = result.herd;
            const herd_name = herd? herd.name: "";
            const num_cows = result.num_cows;
            const butterfat = result.butterfat.toFixed(2) + ' %';
            const protein = result.protein.toFixed(2) + ' %';
            const scc = result.scc;
            const date = new Date(result.time_uploaded);
            const date_string = date.getFullYear() + "-" + String(date.getMonth()+1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0')
            return [index, herd_name, date_string, num_cows, butterfat, protein, scc];
        })
    }

    const onSelect = (index) => {
        setSelectedIndex(index);
        setDetailVisible(true);
    }

    const deleteResult = async () => {
        await api.deleteResult(recentResults[selectedIndex].id);
        hideModal();
        await getRecentResults();
    }

    const downloadRPT = async () =>  {
        await api.downloadRPT(recentResults[selectedIndex].id);
    }

    const downloadOUT = async () => {
        await api.downloadOUT(recentResults[selectedIndex].id);
    }

    const downloadDTA = async () => {
        await api.downloadDTA(recentResults[selectedIndex].id);
    }

    const requiresPC = () => {
        if (herds.length === 0) {
            return false;
        }

        const herd = herds[herdIndex];
        if (!herd.processing_center){
            return false;
        }
        const pcName = herd.processing_center.name;
        return herd.is_official && (pcName === "Agritech" || pcName === "Amelicor");
    }

    const getShifts = () => {
        let shifts = [];

        if (shiftStart1Ref.current.value !== "" && shiftEnd1Ref.current.value !== "") {
            shifts.push({
                start: shiftStart1Ref.current.value,
                end: shiftEnd1Ref.current.value
            });
        }

        if (shiftStart2Ref.current.value !== "" && shiftEnd2Ref.current.value !== "") {
            shifts.push({
                start: shiftStart2Ref.current.value,
                end: shiftEnd2Ref.current.value
            });
        }

        if (shiftStart3Ref.current.value !== "" && shiftEnd3Ref.current.value !== "") {
            shifts.push({
                start: shiftStart3Ref.current.value,
                end: shiftEnd3Ref.current.value
            })
        }

        return shifts;
    }

    const getPickups = () => {
        let pickups = [];

        if (weight1Ref.current.value !== "" && milkings1Ref.current.value !== "" && pickupDate1Ref.current.value !== "") {
            pickups.push({
                weight: weight1Ref.current.value,
                milkings: milkings1Ref.current.value,
                date: pickupDate1Ref.current.value
            });
        }

        if (weight2Ref.current.value !== "" && milkings2Ref.current.value !== "" && pickupDate2Ref.current.value !== "") {
            pickups.push({
                weight: weight2Ref.current.value,
                milkings: milkings2Ref.current.value,
                date: pickupDate2Ref.current.value
            });
        }

        if (weight3Ref.current.value !== "" && milkings3Ref.current.value !== "" && pickupDate3Ref.current.value !== "") {
            pickups.push({
                weight: weight3Ref.current.value,
                milkings: milkings3Ref.current.value,
                date: pickupDate3Ref.current.value
            });
        }

        return pickups;
    }

    const validatePCData = () => {
        if (sampleDateRef.current.value === "") {
            setError("Sample Date is required");
            return false;
        }

        if (receivedDateRef.current.value === "") {
            setError("Received Date is required");
            return false;
        }

        return true;
    }

    const sendPCData = async () => {
        const shifts = getShifts();
        const pickups = getPickups();
        const herd_id = herds[herdIndex].id;
        const receivedDate = receivedDateRef.current.value;
        const sampleDate = sampleDateRef.current.value;
        const notes = notesRef.current.value;

        if (validatePCData()) {
            await api.sendPCData(herd_id, sampleDate, receivedDate, shifts, pickups, notes);
            hideModal();
        }
    }

    const getRecentHerdResults = async () => {
        const herd_id = herds[herdIndex].id;
        const res = await api.getRecentHerdResults(herd_id);
        res.reverse();
        setRecentHerdResults(res);
    }

    useEffect(() => {
        if (herdIndex!==-1) {
            getRecentHerdResults();
        }
    }, [herdIndex])

    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const inputDateFormat = (date) => {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toJSON().slice(0, 10);
    }

    let modalName;
    let modalAction;
    let modalActionText;

    if (stage === "upload") {
        modalName="Upload Data";
        modalActionText = "Upload";
        modalAction = uploadResult;
    } else if (stage === "verify") {
        modalName="Verify Data";
        modalActionText="Verify";
        modalAction=verify;
    } else if (stage === "pc") {
        modalName="Upload PC Data";
        modalActionText="Upload";
        modalAction=sendPCData;
    }

    useEffect(() => {
        if (props.herdId && herds.length !== 0) {
            setUploadVisible(true);
            const index = herds.findIndex((herd) => herd.id===props.herdId);
            setHerdIndex(index);
        }
    }, [herds]);

    // When herdIndex changes set the value for includeWeight
    useEffect(() => {
        if (herdIndex !== -1) {
            const h = herds[herdIndex];
            setIncludeWeight(h.prompt_tank_weights);
        }
    }, [herds, herdIndex])

    return (
        <div className="DataEntryScreen">
            {api.render()}
            <Button text="Upload Data" onClick={() => {setUploadVisible(true)}}/>
            {/* <div style={{height: 16}} /> */}
            {/* <Button text="Manual RPT Entry" type="text" onClick={() => {setManualVisible(true)}}/> */}
            <div style={{height: 64}} />
            {
                recentResults.length === 0?
            <div className="placeholder">No Recent Uploads</div>:
            <div>
                <div className="placeholder">Recent Uploads</div>
                <ClickableTable
                    title="Recent Uploads"
                    onClick={onSelect}
                    headers={["Herd", "Uploaded", "# Cows", "Butterfat", "Protein", "Somatic Cells"]}
                    data={getTableData()}
                    sortBy={2}
                    asc={-1}
                />
            </div>
            }
            <Modal
                visible={uploadVisible}
                title={modalName}
                primaryText={modalActionText}
                onPrimary={modalAction}
                secondaryText="Cancel"
                onSecondary={stage==="verify"? cancelVerify: hideModal}
                error={error}
            >
                {
                    stage === "upload"?
                    <div>
                        <SearchBox text="Search Herds" options={herds.map(h => h.name)} onSelect={setHerdIndex} selected={herdIndex}/>
                        <CheckBox label="Include Tank Weight" checked={includeWeight} setChecked={setIncludeWeight}/>
                        <div className="text">{herds.length > 0 && herdIndex>-1? herds[herdIndex].comments: ""}</div>
                        {
                            includeWeight ? <CurrencyInput placeholder="Tank Weight" innerRef={tankWeightRef} type="number"/>: null
                        }
                        <FilesUpload
                            innerKey={key}
                            fileExt="RPT"
                            onChange={setFiles}
                            files={files}
                            manual={manual}
                            setManual={setManual}
                            force={force}
                            setForce={setForce}
                        />
                    </div>: null
                }
                {
                    stage === "verify"?
                    <div>
                        <table className="verifyTable">
                        {recentHerdResults.length===0? <p style={{whiteSpace: 'nowrap'}}>No historical herd data available</p>:
                            <tbody>
                            <tr>
                                <th>Date</th>
                                <th># Cows</th>
                                <th>MilkWeight</th>
                                <th>Butterfat</th>
                                <th>Protein</th>
                                <th>SCC</th>
                            </tr>
                            {
                                recentHerdResults.map(res => {
                                    const date = new Date(res.time_uploaded);
                                    return (<tr>
                                        <td>{date.getMonth()+1}/{date.getDate()}</td>
                                        <td>{res.num_cows} Cows</td>
                                        <td>{res.milk_weight.toFixed(2)} lb</td>
                                        <td>{res.butterfat.toFixed(2)} %</td>
                                        <td>{res.protein.toFixed(2)} %</td>
                                        <td>{res.scc.toFixed(0)} Cells</td>
                                    </tr>);
                                })
                            }
                            <br/>
                            </tbody>
                        }
                        <tr>
                            <td><b>Today</b></td>
                            <td><b>{summary.num_cows} Cows</b></td>
                            <td><b>{summary.milk_weight.toFixed(2)} lb</b></td>
                            <td><b>{summary.butterfat.toFixed(2)} %</b></td>
                            <td><b>{summary.protein.toFixed(2)} %</b></td>
                            <td><b>{summary.scc} Cells</b></td>
                        </tr>
                    </table>
                    </div>: null
                }
                {
                    stage === "pc"?
                    <div>
                        <div className="double">
                            <DateInput placeholder="Sample Date" innerRef={sampleDateRef} value={inputDateFormat(yesterday)}/>
                            <DateInput placeholder="Date Received" innerRef={receivedDateRef} value={inputDateFormat(today)}/>
                            <p>Shift Times</p><p></p>
                            <TimeInput placeholder="Shift Start" innerRef={shiftStart1Ref} />
                            <TimeInput placeholder="Shift End" innerRef={shiftEnd1Ref} />
                            <TimeInput placeholder="Shift Start" innerRef={shiftStart2Ref} />
                            <TimeInput placeholder="Shift End" innerRef={shiftEnd2Ref} />
                            <TimeInput placeholder="Shift Start" innerRef={shiftStart3Ref} />
                            <TimeInput placeholder="Shift End" innerRef={shiftEnd3Ref} />
                        </div>
                        <p>Recent Pickups</p>
                        <div className="triple">
                            <TextInput placeholder="Weight (lbs)" innerRef={weight1Ref} />
                            <TextInput placeholder="Milkings" innerRef={milkings1Ref} />
                            <DateInput placeholder="Date" innerRef={pickupDate1Ref} />
                            <TextInput placeholder="Weight (lbs)" innerRef={weight2Ref} />
                            <TextInput placeholder="Milkings" innerRef={milkings2Ref} />
                            <DateInput placeholder="Date" innerRef={pickupDate2Ref} />
                            <TextInput placeholder="Weight (lbs)" innerRef={weight3Ref} />
                            <TextInput placeholder="Milkings" innerRef={milkings3Ref} />
                            <DateInput placeholder="Date" innerRef={pickupDate3Ref} />
                        </div>
                        <p>Other Notes</p>
                        <Textarea rows={3} innerRef={notesRef}/>
                    </div>: null
                }
            </Modal>
            <Modal
                title="Upload Details"
                visible={detailVisible}
                primaryText="Cancel"
                onPrimary={hideModal}
                ternaryText="Delete"
                onTernary={deleteResult}
            >
                <Button text="Download RPT" type="text" onClick={downloadRPT}/>
                <div style={{height: 16}}></div>
                <Button text="Download OUT" type="text" onClick={downloadOUT}/>
                <div style={{height: 16}}></div>
                <Button text="Download DTA" type="text" onClick={downloadDTA}/>
            </Modal>
            <Modal
                title="Manual RPT Entry"
                visible={manualVisible}
                primaryText="Download"
                secondaryText="Cancel"
                onSecondary={hideModal}
            >
                <TextInput type="number" placeholder="Pen" />
                <div className="double">
                    <TextInput type="number" placeholder="Cow #" />
                    <TextInput type="number" placeholder="Milkweight" />
                </div>
            </Modal>
        </div>
    );
}


export default DataEntry;
