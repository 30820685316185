import {useState, useEffect} from 'react';
import API from '../util/CustAPI';
import SelectInput from '../components/SelectInput';
import Checkbox from '../components/Checkbox';
import '../styles/AnalyticsScreen.scss';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    RadialBarChart,
    RadialBar,
    ResponsiveContainer,
  } from 'recharts';


const CustomContent = ({
    label,
    data,
    herds,
    showMilkweight,
    showPeerMilkweight,
    showButterfat,
    showPeerButterfat,
    showProtein,
    showPeerProtein,
    showSCC,
    showPeerSCC,
}) => {

    const date = new Date(label);

    const datum = data.find((datum) => {return datum.date === label});


    if (datum === undefined) {
        return <div></div>
    }

    const herd = herds.find((herd) => {return herd.id === datum.herd_id;});

    return (
        <div className="Tooltip">
            <p className="herd">{herd.name}</p>
            <p className="date">{(date.getMonth()+1) + "/" + date.getFullYear()}</p>
            { showMilkweight?
            <div>
                <p id="milkweight">Milkweight:</p>
                <p>{datum.Milkweight} lbs</p>
            </div>: null
            }
            { showPeerMilkweight?
            <div>
                <p id="milkweight-comp">Peer Milkweight:</p>
                <p>{datum.PeerMilkweight} lbs</p>
            </div>: null
            }
            { showButterfat?
            <div>
                <p id="butterfat">Butterfat:</p>
                <p>{datum.Butterfat}%</p>
            </div>: null
            }
            { showPeerButterfat?
            <div>
                <p id="butterfat-comp">Peer Butterfat:</p>
                <p>{datum.PeerButterfat}%</p>
            </div>: null
            }
            {showProtein?
            <div>
                <p id="protein">Protein:</p>
                <p>{datum.Protein}%</p>
            </div>: null
            }
            {showPeerProtein?
            <div>
                <p id="protein-comp">Peer Protein:</p>
                <p>{datum.PeerProtein}%</p>
            </div>: null
            }
            {showSCC?
            <div>
                <p id="scc">SCC:</p>
                <p>{datum.SCC} cells</p>
            </div>: null
            }
            {showPeerSCC?
            <div>
                <p id="scc-comp">Peer SCC:</p>
                <p>{datum.PeerSCC} cells</p>
            </div>: null
            }
        </div>
    )
}

const AnalyticsScreen = (props) => {
    const api: API = props.API;

    const [results, setResults] = useState([]);
    const [herds, setHerds] = useState([]);
    const [herdIndex, setHerdIndex] = useState(0);
    const [averages, setAverages] = useState([]);
    const [percentiles, setPercentiles] = useState([]);
    const [percentilesReady, setPercentilesReady] = useState(false);
    const [compareHerdIndex, setCompareHerdIndex] = useState(0);
    const [competitorAverages, setPeerAverages] = useState([]);
    const [displayGraph, setDisplayGraph] = useState(true);

    // Comparison Parameters
    const [sameBreed, setSameBreed] = useState(false);
    const [sameRegion, setSameRegion] = useState(false);
    const [sameMilkings, setSameMilkings] = useState(false);

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const getResults = async () => {
        setResults(await api.getResults());
    }

    const getHerds = async () => {
        const new_herds = await api.getActiveHerds();
        setPercentilesReady(false);
        setHerds(new_herds);

        let percs = []
        for (let i=0; i<new_herds.length; i++) {
            percs.push(getHerdPercentiles(new_herds[i]));
        }

        for (let i=0; i<new_herds.length; i++) {
            percs[i] = await percs[i];
        }
        setPercentiles(percs);
        setPercentilesReady(true);
    }

    const getAverages = async () => {
        setAverages(await api.getAverageResults());
    }

    const getPeerAverages = async () => {
        const herdId = herdIndex === 0? "00000000-0000-0000-0000-000000000000": herds[herdIndex-1].id;
        setPeerAverages(await api.getAllAverageResults(herdId, sameBreed, sameRegion, sameMilkings));
    }

    const getHerdPercentiles = async (herd) => {
        // const sameBreed = compareToIndex === 2;
        // const sameRegion = compareToIndex === 1;
        // const sameMilkings = compareToIndex === 3;
        return await api.getPercentiles(herd.id, sameBreed, sameRegion, sameMilkings);
    }

    const updatePercentiles = async () => {
        let percs = []
        for (let i=0; i<1; i++) {
            percs.push(getHerdPercentiles(herds[i]));
        }

        for (let i=0; i<1; i++) {
            percs[i] = await percs[i];
        }
        setPercentiles(percs);
    }

    useEffect(() => {
        getHerds();
        getResults();
        getAverages();
    }, [])

    useEffect(() => {
        getPeerAverages();
        if (percentilesReady) {
            updatePercentiles();
        }
    }, [sameBreed, sameRegion, sameMilkings]);

    const getData = () => {
        if (herds.length === 0 || averages.length === 0 || competitorAverages.length === 0) {
            return [];
        }

        if (herdIndex === 0) {
            return averages.map((result) => {
                const date = new Date(result.year, result.month, 0);
                let c = competitorAverages.find((v) => {
                    return v.month === result.month && v.year === result.year;
                });

                return {
                    herd_id: "||AVERAGE||",
                    date: date.getTime(),
                    Milkweight: result.milk_weight.toFixed(2),
                    PeerMilkweight: c? c.milk_weight.toFixed(2): "",
                    Butterfat: result.butterfat.toFixed(2),
                    PeerButterfat: c? c.butterfat.toFixed(2): "",
                    Protein: result.protein.toFixed(2),
                    PeerProtein: c? c.protein.toFixed(2): "",
                    SCC: result.scc.toFixed(2),
                    PeerSCC: c? c.scc.toFixed(2): ""
                }
            })
        }

        const herd = herds[herdIndex-1];

        return results
            .filter((res) => {
                return res.herd.id === herd.id
            })
            .reverse()
            .map((result) => {
            const date = new Date(result.time_uploaded);

            let c;
            c = competitorAverages.find((v) => {
                return v.month === date.getMonth()+1 && v.year === date.getFullYear();
            });

            return {
                herd_id: result.herd.id,
                date: date.getTime(),
                Milkweight: result.milk_weight.toFixed(2),
                PeerMilkweight: c? c.milk_weight.toFixed(2): "",
                Butterfat: result.butterfat.toFixed(2),
                PeerButterfat: c? c.butterfat.toFixed(2): "",
                Protein: result.protein.toFixed(2),
                PeerProtein: c? c.protein.toFixed(2): "",
                SCC: result.scc,
                PeerSCC: c? c.scc.toFixed(2): ""
            }
        });
    }

    const getHerdOptions = () => {
        return [{name: "Herd Average", id:"||AVERAGE||"}].concat(herds);
    }

    const data = getData();
    const herdOptions = getHerdOptions();

    let mw_percentile = 0;
    let bf_percentile = 0;
    let pn_percentile = 0;
    let scc_percentile = 0;

    if (percentilesReady && percentiles.length !== 0) {
        mw_percentile = percentiles[compareHerdIndex].milk_weight;
        bf_percentile = percentiles[compareHerdIndex].butterfat;
        pn_percentile = percentiles[compareHerdIndex].protein;
        scc_percentile = percentiles[compareHerdIndex].scc;
    }


    const mw_message = `Ranks better than ${(100*mw_percentile).toFixed(0)}% of competitors`;
    const bf_message = `Ranks better than ${(100*bf_percentile).toFixed(0)}% of competitors`;
    const pn_message = `Ranks better than ${(100*pn_percentile).toFixed(0)}% of competitors`;
    const scc_message = `Ranks better than ${(100*scc_percentile).toFixed(0)}% of competitors`;

    return (
        <div className="AnalyticsScreen">
            <div className="Graph">
        {
            displayGraph?
            <div style={{
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                gridTemplateColumns: "1fr 1fr",
                width: "100%",
                height: "100%"
            }}>
                <div>
                <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                        <CartesianGrid strokeDasharray="5 5" />
                        <XAxis
                            dataKey="date"
                            type="number"
                            name="Date"
                            tickFormatter={(unixtime) => {
                                if (!Number.isFinite(unixtime)) {
                                    return "";
                                }
                                const date = new Date(unixtime);
                                return monthNames[date.getMonth()].slice(0,3) + " " + date.getFullYear();
                            }}
                            domain={['dataMin', 'dataMax']}
                        />
                        <YAxis yAxisId="left" domain={['dataMin', 'dataMax']} />
                        <Tooltip content={
                            <CustomContent
                                data={data}
                                herds={herdOptions}
                                showMilkweight={true}
                                showPeerMilkweight={true}
                                showButterfat={false}
                                showPeerButterfat={false}
                                showProtein={false}
                                showPeerProtein={false}
                                showSCC={false}
                                showPeerSCC={false}
                            />
                        }/>
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="Milkweight" name="Milkweight" stroke="#4D76B3" dot={false}/>
                        <Line yAxisId="left" type="monotone" dataKey="PeerMilkweight" name="Peer Milkweight" stroke="#0db7c6" dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
                </div>
                <div>
                <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                        <CartesianGrid strokeDasharray="5 5" />
                        <XAxis
                            dataKey="date"
                            type="number"
                            name="Date"
                            tickFormatter={(unixtime) => {
                                if (!Number.isFinite(unixtime)) {
                                    return "";
                                }
                                const date = new Date(unixtime);
                                return monthNames[date.getMonth()].slice(0,3) + " " + date.getFullYear();
                            }}
                            domain={['dataMin', 'dataMax']}
                        />
                        <YAxis yAxisId="left" domain={['dataMin', 'dataMax']}/>
                        <Tooltip content={
                            <CustomContent
                                data={data}
                                herds={herdOptions}
                                showMilkweight={false}
                                showPeerMilkweight={false}
                                showButterfat={true}
                                showPeerButterfat={true}
                                showProtein={false}
                                showPeerProtein={false}
                                showSCC={false}
                                showPeerSCC={false}
                            />
                        }/>
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="Butterfat" name="Butterfat" stroke="#F7C548" dot={false} />
                        <Line yAxisId="left" type="monotone" dataKey="PeerButterfat" name="Peer Butterfat" stroke="#e28c0b" dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
                </div>
                <div>
                <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                        <CartesianGrid strokeDasharray="5 5" />
                        <XAxis
                            dataKey="date"
                            type="number"
                            name="Date"
                            tickFormatter={(unixtime) => {
                                if (!Number.isFinite(unixtime)) {
                                    return "";
                                }
                                const date = new Date(unixtime);
                                return monthNames[date.getMonth()].slice(0,3) + " " + date.getFullYear();
                            }}
                            domain={['dataMin', 'dataMax']}
                        />
                        <YAxis yAxisId="left" domain={['dataMin', 'dataMax']}/>
                        <Tooltip content={
                            <CustomContent
                                data={data}
                                herds={herdOptions}
                                showMilkweight={false}
                                showPeerMilkweight={false}
                                showButterfat={false}
                                showPeerButterfat={false}
                                showProtein={true}
                                showPeerProtein={true}
                                showSCC={false}
                                showPeerSCC={false}
                            />
                        }/>
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="Protein" name="Protein" stroke="#ED8173" dot={false}/>
                        <Line yAxisId="left" type="monotone" dataKey="PeerProtein" name="Peer Protein" stroke="#d82d13" dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
                </div>
                <div>
                <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                        <CartesianGrid strokeDasharray="5 5" />
                        <XAxis
                            dataKey="date"
                            type="number"
                            name="Date"
                            tickFormatter={(unixtime) => {
                                if (!Number.isFinite(unixtime)) {
                                    return "";
                                }
                                const date = new Date(unixtime);
                                return monthNames[date.getMonth()].slice(0,3) + " " + date.getFullYear();
                            }}
                            domain={['dataMin', 'dataMax']}
                        />
                        <YAxis yAxisId="left" domain={['dataMin', 'dataMax']}/>
                        <Tooltip content={
                            <CustomContent
                                data={data}
                                herds={herdOptions}
                                showMilkweight={false}
                                showPeerMilkweight={false}
                                showButterfat={false}
                                showPeerButterfat={false}
                                showProtein={false}
                                showPeerProtein={false}
                                showSCC={true}
                                showPeerSCC={true}
                            />
                        }/>
                        <Legend />
                        <Line type="monotone" yAxisId="left" dataKey="SCC" name="SCC" stroke="#57D973" dot={false} />
                        <Line yAxisId="left" type="monotone" dataKey="PeerSCC" name="Peer SCC" stroke="#1c820a" dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
                </div>
                </div>:
                <div style={{overflowY: 'scroll'}} className="AnalTable">
                    <table>
                        <tr>
                            <th>Date</th>
                            <th>Milkweight</th>
                            <th>Peer Milkweight</th>
                            <th>Butterfat</th>
                            <th>Peer Butterfat</th>
                            <th>Protein</th>
                            <th>Peer Protein</th>
                            <th>SCC</th>
                            <th>Peer SCC</th>
                        </tr>
            {
                data.reverse().map((val) => {
                    const date = new Date(val.date);
                    return (
                        <tr>
                            <td>{(date.getMonth()+1) + "/" + date.getFullYear()}</td>
                            <td>{val.Milkweight}</td>
                            <td>{val.PeerMilkweight}</td>
                            <td>{val.Butterfat}</td>
                            <td>{val.PeerButterfat}</td>
                            <td>{val.Protein}</td>
                            <td>{val.PeerProtein}</td>
                            <td>{val.SCC}</td>
                            <td>{val.PeerSCC}</td>
                        </tr>
                    );
                })
            }
                    </table>
                </div>
        }
            </div>
            <div className="Percentiles">
                <h3>Compare to Peers</h3>
                <div>
                    <SelectInput
                        placeholder="Herd"
                        options={herds}
                        selectedIndex={compareHerdIndex}
                        onSelect={setCompareHerdIndex}
                    />
                    <Checkbox label="Same Breed" checked={sameBreed} setChecked={setSameBreed} />
                    <Checkbox label="Same Region" checked={sameRegion} setChecked={setSameRegion} />
                    <Checkbox label="Same Milkings" checked={sameMilkings} setChecked={setSameMilkings} />
                </div>
                <div className="rankContainer">
                <div className="rank">
                    <h4>Milkweight</h4>
                    <RadialBarChart 
                        width={200} 
                        height={200} 
                        data={[{value: 1.0, fill: '#fff'}, {value: mw_percentile, fill: '#4d76b3'}]} 
                        startAngle={180} 
                        endAngle={0}
                        barSize={20}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    >
                        <RadialBar background clockWise={true} dataKey='value' />
                    </RadialBarChart>
                    <p id="percentile">{mw_message}</p>
                </div>
                <div className="rank">
                    <h4>Butterfat</h4>
                    <RadialBarChart 
                        width={200} 
                        height={200} 
                        data={[{value: 1.0, fill: '#fff'}, {value: bf_percentile, fill: '#f7c548'}]} 
                        startAngle={180} 
                        endAngle={0}
                        barSize={20}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    >
                        <RadialBar background clockWise={true} dataKey='value' />
                    </RadialBarChart>
                    <p id="percentile">{bf_message}</p>
                </div>
                <div className="rank">
                    <h4>Protein</h4>
                    <RadialBarChart 
                        width={200} 
                        height={200} 
                        data={[{value: 1.0, fill: '#fff'}, {value: pn_percentile, fill: '#ED8173'}]} 
                        startAngle={180} 
                        endAngle={0}
                        barSize={20}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    >
                        <RadialBar background clockWise={true} dataKey='value' />
                    </RadialBarChart>
                    <p id="percentile">{pn_message}</p>
                </div>
                <div className="rank">
                    <h4>Somatic Cell Count</h4>
                    <RadialBarChart 
                        width={200} 
                        height={200} 
                        data={[{value: 1.0, fill: '#fff'}, {value: scc_percentile, fill: '#57d973'}]} 
                        startAngle={180} 
                        endAngle={0}
                        barSize={20}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    >
                        <RadialBar background clockWise={true} dataKey='value' />
                    </RadialBarChart>
                    <p id="percentile">{scc_message}</p>
                </div>
                </div>
            </div>
            <div className="Options">
                <h3>Graph Controls</h3>
                <div className="controls">
                    <div className="entities">
                        <br></br>
                        <SelectInput placeholder="Display" options={[{name: "Graph", id: 0}, {name: "Text", id: 1}]} selectedIndex={displayGraph?0:1} onSelect={(v) => {setDisplayGraph(!v)}}/>
                        <SelectInput placeholder="Herd" options={herdOptions} selectedIndex={herdIndex} onSelect={setHerdIndex}/>
                    </div>
                    <div>
                        <p>Filter Peers</p>
                        <Checkbox label="Same Breed" checked={sameBreed} setChecked={setSameBreed} />
                        <Checkbox label="Same Region" checked={sameRegion} setChecked={setSameRegion} />
                        <Checkbox label="Same Milkings" checked={sameMilkings} setChecked={setSameMilkings} />
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AnalyticsScreen;
