import { useState, useEffect } from 'react';
import SelectInput from '../components/SelectInput';

import '../styles/ResultDetail.scss';
import Modal from '../components/Modal';

const ResultDetail = (props) => {
    const result = props.result;
    const herd = props.herd;

    const api = props.API;

    const date = new Date(result.time_uploaded);
    const fileName = date.toLocaleDateString().replaceAll("/", "-");
    const prettyDate = date.toLocaleDateString();

    const [butterfat, setButterfat] = useState(0);
    const [protein, setProtein] = useState(0);
    const [somaticCells, setSomaticCells] = useState(0);
    const [paymentVisible, setPaymentVisible] = useState(false);
    const [munRate, setMunRate] = useState("x");

    const getDTA = async () => {
        await api.downloadDTA(result.id, `${fileName}.DTA`);
    }

    const getCSV = async () => {
        await api.downloadCSV(result.id, `${fileName}.csv`);
    }

    const getExcel = async () => {
        await api.downloadExcel(result.id, `${fileName}.xlsx`);
    }

    const getMUN = async () => {
        await api.downloadMUN(result.id, `${fileName}-mun.csv`)
    }

    useEffect(() => {
        const getDelta = async () => {
            const deltas = await api.getPreviousDeltas(result.id);
            setButterfat(deltas.butterfat);
            setProtein(deltas.protein);
            setSomaticCells(deltas.somatic_cells);
        }
        getDelta();
    }, [api, result]);

    const file_options = ["DTA", "CSV", "XLSX (Excel)", "MUN"];

    const downloadSelected = (index) => {
        switch (index) {
            case 0:
                getDTA();
                break;
            case 1:
                getCSV();
                break;
            case 2:
                getExcel();
                break;
            case 3:
                setPaymentVisible(true);
                break;
            default:
                break;
        }
    }

    const hideModal = () => {
        setPaymentVisible(false);
    }

    const formatNumber = (num) => {
        let prefix = "";

        if (num < 0) {
            prefix = "";
        } else {
            prefix = "+";
        }

        return prefix + num.toFixed(2);
    }

    const butterfatColor = butterfat < 0? "red": "green";
    const proteinColor = protein < 0? "red": "green";
    const sccColor = somaticCells < 0? "green": "red";

    const getMunRate = async () => {
        const r = await api.getMunRate();
        setMunRate('$' +r.mun_rate.toFixed(2));
    }

    useState(() => {
        getMunRate();
    }, [])

    return (
        <div className="ResultDetail">
            <div>
                <div className="details">
                    <p className="title">Test for {prettyDate}</p>
                    <p className="date">{herd.name}</p>
                </div>

                {result.imported? null:
                <SelectInput
                    selectedIndex="-1"
                    default="Download"
                    options={file_options.map((e,i) => {return {id:i, name:e};})}
                    onSelect={downloadSelected}
                    removeFirst={true}
                />
                }

            </div>
            <div>
                <p>Change Since Last Test</p>
                <div className="deltas">
                    <div>
                        <p>Butterfat</p>
                        <p className={butterfatColor}>{formatNumber(butterfat)}</p>
                    </div>
                    <div>
                        <p>Protein</p>
                        <p className={proteinColor}>{formatNumber(protein)}</p>
                    </div>
                    <div>
                        <p>Somatic Cells</p>
                        <p className={sccColor}>{formatNumber(somaticCells)}</p>
                    </div>
                </div>
            </div>
            <Modal
                title="Extra Charge Notice"
                visible={paymentVisible}
                primaryText="Proceed"
                onPrimary={() => {getMUN(); hideModal();}}
                secondaryText="Cancel"
                onSecondary={hideModal}
            >
                <p style={{width: 300}}>Downloading MUN data will incur a one time charge of {munRate} per cow. Proceed?</p>
            </Modal>
        </div>
    );
}

export default ResultDetail;
