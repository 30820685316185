import Button from "../components/Button";
import {useState, useRef, useEffect} from 'react';
import Modal from "../components/Modal";
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import Textarea from "../components/Textarea";
import API from '../util/AdminAPI'
import '../styles/AdminScreen.scss';

import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ReferenceArea,
    ResponsiveContainer,
  } from 'recharts';

  const CustomContent = ({label, data}) => {

    const date = new Date(label).toLocaleDateString([], {hour: '2-digit', minute:'2-digit'});
    const datum = data.find((datum) => {return datum.date === label});

    if (datum === undefined) {
        return <div></div>
    }

    return (
        <div className="Tooltip">
            <p className="date">{datum.prettyDate}</p>
            <div>
                <p id="milkweight">Hours:</p>
                <p>{datum.hours} hours</p>
            </div>
            <div>
                <p id="protein">Samples:</p>
                <p>{datum.samples} Samples</p>
            </div>
        </div>
    )
}

const AdminScreen = (props) => {
    const api: API = props.API;

    const [emailVisible, setEmailVisible] = useState(false);
    const [error, setError] = useState("");
    const [hoursError, setHoursError] = useState("");
    const [associationError, setAssociationError] = useState("");
    const [groupby, setGroupby] = useState(0);
    const [analytics, setAnalytics] = useState([]);

    // Graph zoom state
    const [refAreaLeft, setRefAreaLeft] = useState('');
    const [refAreaRight, setRefAreaRight] = useState('');

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const subjectRef = useRef(null);
    const bodyRef = useRef(null);
    const hoursRef = useRef(null);
    const yearRef = useRef(null);
    const monthRef = useRef(null);
    const hoursDayRef = useRef(null);
    const hoursMonthRef = useRef(null);
    const hoursYearRef = useRef(null);

    const getAnalytics = async () => {
        setAnalytics(await api.getAdminAnalytics(groupby));
    }

    useEffect(() => {
        getAnalytics();
    }, [groupby]);

    const showEmailModal = () => {
        setEmailVisible(true);
    }

    const hideModal = () => {
        setEmailVisible(false);

        subjectRef.current.value = "";
        bodyRef.current.value = "";
    }

    const sendEmail = async () => {
        const subject = subjectRef.current.value;
        const body = bodyRef.current.value;

        if (subjectRef !== "" && body !== "" ) {
            await api.sendEmail(subject, body);
            hideModal();
        } else {
            setError("Subject and Body cannot be blank");
        }
    }

    const validateHours = () => {
        if (isNaN(hoursRef.current.value) || !hoursRef.current.value) {
            setHoursError("Hours must be a number");
            return false;
        }

        return true;
    }

    const addHours = async () => {
        if (validateHours()) {
            const hours = hoursRef.current.value;
            const day = hoursDayRef.current.value;
            const month = hoursMonthRef.current.value;
            const year = hoursYearRef.current.value;

            await api.addHours(hours, day, month, year);
            hoursRef.current.value = "";
            await getAnalytics();
        }
    }

    const getAssociationReport = async () => {
        if (validateDate()) {
            const year = yearRef.current.value;
            const month = monthRef.current.value;

            api.getAssociationReport(year, month);
        }
    }

    const validateDate = () => {
        if (isNaN(yearRef.current.value) || !yearRef.current.value) {
            return false;
        }

        if (isNaN(monthRef.current.value) || !monthRef.current.value) {
            return false;
        }

        return true;
    }

    const getData = () => {
        return analytics.map((entry) => {
            let date;
            if (groupby === 0 && entry.year && entry.month && entry.day) {
                date = new Date(entry.year, entry.month-1, entry.day);
            } else if (groupby === 1 && entry.month && entry.year) {
                date = new Date(entry.year, entry.month-1);
            } else {
                date = new Date(entry.year, 0);
            }

            let prettyDate;

            if (groupby===0) {
                prettyDate =  (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();
            } else if (groupby===1) {
                prettyDate = monthNames[date.getMonth()] + " " + date.getFullYear();
            } else {
                prettyDate = date.getFullYear();
            }


            return {
                date: date.getTime(),
                hours: entry.hours,
                samples: entry.samples,
                prettyDate: prettyDate,
            }
        });
    }

    const today = new Date();

    return (
        <div className="AdminAdminScreen">
            {api.render()}
            <div id="admin-actions">
                <div>
                    <div style={{marginTop: 64}}>
                        <TextInput type="number" placeholder="Day" value={today.getDate()} innerRef={hoursDayRef} />
                        <TextInput type="number" placeholder="Month" value={today.getMonth()+1} innerRef={hoursMonthRef} />
                        <TextInput type="number" placeholder="Year" value={today.getFullYear()} innerRef={hoursYearRef} />
                        <TextInput type="number" placeholder="Hours" innerRef={hoursRef}/>
                        <Button text="Add Hours" type="text" onClick={addHours}/>
                        <p>{hoursError}</p>
                    </div>
                    <div style={{marginTop: 32}}>
                        <Button text="Email All Customers" onClick={showEmailModal}/>
                    </div>
                </div>
                <div style={{marginTop: 64}}>
                    <TextInput type="number" placeholder="Month" value={today.getMonth()+1} innerRef={monthRef} />
                    <TextInput type="number" placeholder="Year" value={today.getFullYear()} innerRef={yearRef} />
                    <p>{associationError}</p>
                    <br></br>
                    <Button text="Association Member Report" onClick={getAssociationReport}/>
                </div>
            </div>
            <div id="admin-graph">
                <div className="highlight-bar-charts" style={{ userSelect: 'none', width: '100%' }}>
                    <ResponsiveContainer width="80%" height={400}>
                        <LineChart
                            width={800}
                            height={400}
                            data={getData()}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis allowDataOverflow dataKey="date" domain={['auto', 'auto']} type="number"
                                tickFormatter={(unixtime) => {
                                    const date = new Date(unixtime);
                                    if (groupby===0) {
                                        return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();
                                    } else if (groupby===1) {
                                        return monthNames[date.getMonth()] + " " + date.getFullYear();
                                    } else {
                                        return date.getFullYear();
                                    }
                                }}
                            />
                            <YAxis allowDataOverflow domain={[0, 'auto']} type="number" yAxisId="1" />
                            <YAxis allowDataOverflow domain={[0, 'auto']} type="number" yAxisId="2" orientation="right" />
                            <Tooltip content={
                                <CustomContent data={getData()} />
                            }/>
                            <Line yAxisId="1" type="number" dataKey="hours" stroke="#4D76B3" animationDuration={300} />
                            <Line yAxisId="2" type="number" dataKey="samples" stroke="#ed8173" animationDuration={300} />
                            {refAreaLeft && refAreaRight ? (
                                <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                            ) : null}
                        </LineChart>
                    </ResponsiveContainer>
                    <div style={{height: 16}} />
                </div>
            </div>
            <div id="admin-table">
                <SelectInput
                    placeholder="Group By"
                    options={[{name: "Day", id: 0}, {name: "Month", id: 1}, {name: "Year", id: 2}]}
                    selectedIndex={groupby}
                    onSelect={setGroupby}
                >
                </SelectInput>
                <table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Samples</th>
                        <th>Hours</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        analytics.map((v) => {
                            let date = "";
                            if (groupby===0 && v.month && v.day && v.year) {
                                date = v.month + "/" + v.day + "/" + v.year;
                            } else if (groupby===1 && v.month && v.year) {
                                date = monthNames[v.month-1].slice(0,3) + " " + v.year;
                            } else {
                                date = v.year;
                            }

                            return (
                                <tr key={v.id}>
                                    <td>{date}</td>
                                    <td>{v.samples}</td>
                                    <td>{v.hours}</td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
            <Modal
                title="Send Announcement"
                visible={emailVisible}
                primaryText="Send"
                onPrimary={sendEmail}
                secondaryText="Cancel"
                onSecondary={hideModal}
                error={error}
            >
                <TextInput placeholder="Subject" innerRef={subjectRef}/>
                <p>Message</p>
                <Textarea rows={10} innerRef={bodyRef}/>
            </Modal>
        </div>
    )
}

export default AdminScreen;
