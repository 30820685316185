import {Redirect} from 'react-router-dom';

const base_url = process.env.NODE_ENV === "development"? "http://localhost:5000/api": `${window.location.origin}/api`;

export default class AdminAPI {

    constructor() {
        this.loggedIn = false;
        this.requireEmail = false;
        this.listeners = []

        // Check if user has valid token
        this.readToken();
    }

    addListener = (func) => {
        this.listeners.push(func);
    }

    removeListener = (func) => {
        this.listeners = this.listeners.filter(val => val !== func);
    }

    alert = (msg) => {
        this.listeners.forEach((func) => {func(msg);});
    }

    addToken = (urlString) => {
        return urlString + "?" + new URLSearchParams({token: this.token});
    }

    fetch = async (url, options) => {
        const r = await fetch(url, options);
        if (r.status === 403) {
            this.loggedIn = false;
        }

        return r;
    }

    download = async (url) => {
        const new_url = this.addToken(url);
        window.open(new_url, '_blank');
    }

    login = async (email, password) => {
        const r = await this.fetch(`${base_url}/auth/customer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        });

        if (r.status === 200) {
            const json = await r.json();
            this.token = json.token;
            this.writeToken();
            this.loggedIn = true;
            this.id = json.id;
            this.requireEmail = json.require_email;
            this.alert("Successfully logged in");
        } else {
            this.alert("Invalid Username/Password");
        }
    }

    checkLogin = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/auth/customer`));

        this.loggedIn = r.status === 200;
        const json = await r.json();
        this.id = json.id;
    }

    readToken = () => {
        this.token = localStorage.getItem("token");
    }

    writeToken = () => {
        localStorage.setItem("token", this.token);
    }

    getHerds = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/herds/`));
        return await r.json();
    }

    getActiveHerds = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/herds/active`));
        return await r.json();
    }

    updateHerd = async (herdId, data) => {
        data.token = this.token;

        let res = await this.fetch(`${base_url}/herds/${herdId}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        return res.status === 200;

    }

    getResults = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/results/`));
        const json = await r.json();
        return json;
    }

    getPreviousDeltas = async (id) => {
        const r = await this.fetch(this.addToken(`${base_url}/results/${id}/delta`));
        return await r.json();
    }

    downloadDTA = async (id, _) => {
        await this.download(`${base_url}/results/${id}/dta`);
    }

    downloadCSV = async (id, _) => {
        await this.download(`${base_url}/results/${id}/csv`);
    }

    downloadExcel = async (id, _) => {
        this.download(`${base_url}/results/${id}/excel`);
    }

    downloadMUN = async (id, _) => {
        this.download(`${base_url}/results/${id}/mun`);
    }

    updateEmail = async (email) => {
        const data = {
            email: email,
            token: this.token
        };

        let res = await this.fetch(`${base_url}/auth/update_email`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        return res.status === 200;
    }

    sendRequest = async (name, email) => {
        const data = {
            email: email,
            name: name,
            token: this.token
        };

        await this.fetch(`${base_url}/auth/send_request`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    getCustomerContacts = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/contacts/customer/${this.id}`));
        const json = await r.json();
        return json;
    }

    newCustomerContact = async (name, email, mobile_num, addr1, addr2, city, state, zipcode) => {
        const data = {
            name: name,
            email: email,
            mobile_num: mobile_num,
            addr1: addr1,
            addr2: addr2,
            city: city,
            state: state,
            zipcode: zipcode,
            token: this.token
        }

        await this.fetch(`${base_url}/contacts/customer/${this.id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    getHerdContacts = async (id) => {
        const r = await this.fetch(this.addToken(`${base_url}/contacts/herd/${id}`));
        const json = await r.json();
        return json;
    }

    newHerdContact = async (herd_id, name, email, mobile_num, addr1, addr2, city, state, zipcode) => {
        const data = {
            name: name,
            email: email,
            mobile_num: mobile_num,
            addr1: addr1,
            addr2: addr2,
            city: city,
            state: state,
            zipcode: zipcode,
            token: this.token
        }

        await this.fetch(`${base_url}/contacts/herd/${herd_id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    updateContact = async (id, data) => {
        const d = data;
        d.token = this.token;
        await this.fetch(`${base_url}/contacts/${id}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(d)
        });
    }

    deleteCustomerContact = async (id) => {
        await this.fetch(this.addToken(`${base_url}/contacts/${id}/customer/${this.id}`), {
            method: "DELETE"
        });
    }

    deleteHerdContact = async (id, herd_id) => {
        await this.fetch(this.addToken(`${base_url}/contacts/${id}/herd/${herd_id}`), {
            method: "DELETE"
        });
    }

    sendReset = async (email) => {
        await fetch(`${base_url}/auth/customer/send_reset`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email
            })
        })
    }

    resetPassword = async (id, password) => {
        const r = await fetch(`${base_url}/auth/customer/reset`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                auth_code: id,
                password: password
            })
        });

        if (r.status === 200) {
            const json = await r.json();
            this.token = json.token;
            this.writeToken();
            this.loggedIn = true;
            this.id = json.id;
            this.alert("Successfully logged in");
        } else {
            this.alert("Invalid Reset Link. Please request another link and try again.");
        }
    }

    // Reports
    getFavoriteReports = async (herdId) => {
        await this.download(`${base_url}/reports/favorites/${herdId}`);
    }

    getStandardReport = async (herdId) => {
        await this.download(`${base_url}/reports/standard/${herdId}`);
    }

    getSummaryReport = async (herdId) => {
        await this.download(`${base_url}/reports/summary/${herdId}`);
    }

    getSummaryMUNReport = async (herdId) => {
        await this.download(`${base_url}/reports/summary_mun/${herdId}`);
    }

    getEnergyCorrectedReport = async (herdId) => {
        await this.download(`${base_url}/reports/energy_corrected_milkweights/${herdId}`);
    }

    getFatCorrectedReport = async (herdId) => {
        await this.download(`${base_url}/reports/fat_corrected_milkweights/${herdId}`);
    }

    getLowMilkweightReport = async (herdId) => {
        await this.download(`${base_url}/reports/low_milkweight/${herdId}`);
    }

    getLowMilkweightPenReport = async (herdId) => {
        await this.download(`${base_url}/reports/low_milkweight_by_pen/${herdId}`);
    }

    getByMilkweightReport = async (herdId) => {
        await this.download(`${base_url}/reports/by_milkweight/${herdId}`);
    }

    getByPenReport = async (herdId) => {
        await this.download(`${base_url}/reports/by_pen/${herdId}`);
    }

    getByPenMilkweightReport = async (herdId) => {
        await this.download(`${base_url}/reports/by_pen_milkweight/${herdId}`);
    }

    getBySCCReport =async (herdId) => {
        await this.download(`${base_url}/reports/by_scc/${herdId}`);
    }

    getSCCContributionReport = async (herdId) => {
        await this.download(`${base_url}/reports/scc_contribution/${herdId}`);
    }

    getSCCOver500Report = async (herdId) => {
        await this.download(`${base_url}/reports/scc_over_500/${herdId}`);
    }

    getAverageResults = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/analysis/average`));
        return await r.json();
    }

    getAllAverageResults = async (herdId, sameBreed, sameRegion, sameMilkings) => {
        const data = {
            herd_id: herdId,
            same_breed: sameBreed,
            same_region: sameRegion,
            same_milkings: sameMilkings,
            token: this.token
        }
        const r = await this.fetch(`${base_url}/analysis/average/all`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)

        });
        return await r.json();
    }

    getPercentiles = async (herd_id, sameBreed, sameRegion, sameMilkings) => {
        const data = {
            same_breed: sameBreed,
            same_region: sameRegion,
            same_milkings: sameMilkings,
            token: this.token
        }

        const r = await this.fetch(`${base_url}/analysis/percentiles/${herd_id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await r.json();
    }

    getDownloadLink = (id) => {
        return this.addToken(`${base_url}/results/${id}/dta`);
    }

    getMunRate = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/results/get_mun_rate`));
        return await r.json();
    }

    render = () => {
        if (!this.loggedIn) {
            return <Redirect to={{pathname: "/cloud/login"}} />;
        } else if (this.requireEmail) {
            return <Redirect to={{pathname: "/cloud/email_form"}} />;
        } else {
            return <div />;
        }
    }
}
