import {useEffect, useRef, useState} from 'react';

import ContactCard from '../components/ContactCard';
import Modal from '../components/Modal';
import Button from  '../components/Button';
import TextInput from '../components/TextInput';
import SelectInput from '../components/SelectInput';

import states from '../util/states';
import API from '../util/CustAPI';

const CustomerContacts = (props) => {
    const api: API = props.API;
    const empty_state = [{name: "None", abbreviation: ""}]
    const state_options = empty_state.concat(states)

    const [globalContacts, setGlobalContacts] = useState([]);
    const [herdContacts, setHerdContacts] = useState([[]]);
    const [herds, setHerds] = useState([]);
    const [herdIndex, setHerdIndex] = useState(-1);
    const [herdContactIndex, setHerdContactIndex] = useState(0);
    const [globalContactIndex, setGlobalContactIndex] = useState(0);
    const [stateIndex, setStateIndex] = useState(0);
    const [newModalVisible, setNewModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [modalError, setModalError] = useState("");

    // Refs
    const newNameRef = useRef(null);
    const newEmailRef = useRef(null);
    const newMobileNumRef = useRef(null);
    const newAddr1Ref = useRef(null);
    const newAddr2Ref = useRef(null);
    const newCityRef = useRef(null);
    const newZipcodeRef = useRef(null);

    const editNameRef = useRef(null);
    const editEmailRef = useRef(null);
    const editMobileNumRef = useRef(null);
    const editAddr1Ref = useRef(null);
    const editAddr2Ref = useRef(null);
    const editCityRef = useRef(null);
    const editZipcodeRef = useRef(null);

    const getHerds = async () => {
        const new_herds = await api.getActiveHerds();

        let new_herd_contacts = []
        for (let i=0; i<new_herds.length; i++) {
            new_herd_contacts.push(await api.getHerdContacts(new_herds[i].id));
        }
        setHerdContacts(new_herd_contacts);
        setHerds(new_herds);
    }

    const getGlobalContacts = async () => {
        setGlobalContacts(await api.getCustomerContacts());
    }

    useEffect(() => {
        getHerds();
        getGlobalContacts();
    }, []);

    const addContact = async () => {
        const name = newNameRef.current.value;
        const email = newEmailRef.current.value;
        const mobile_num = newMobileNumRef.current.value;
        const addr1 = newAddr1Ref.current.value;
        const addr2 = newAddr2Ref.current.value;
        const city = newCityRef.current.value;
        const state = state_options[stateIndex].abbreviation;
        const zipcode = newZipcodeRef.current.value;

        if (validateContact(name, email)) {
            if (herdIndex === -1) {
                await api.newCustomerContact(name, email, mobile_num, addr1, addr2, city, state, zipcode);
                await getGlobalContacts();
            } else {
                await api.newHerdContact(herds[herdIndex].id, name, email, mobile_num, addr1, addr2, city, state, zipcode);
                await getHerds();
            }
            hideModal();
        }
    }

    const editContact = async () => {
        const data = {
            name: editNameRef.current.value,
            email: editEmailRef.current.value,
            mobile_num: editMobileNumRef.current.value,
            addr1: editAddr1Ref.current.value,
            addr2: editAddr2Ref.current.value,
            city: editCityRef.current.value,
            state: state_options[stateIndex].abbreviation,
            zipcode: editZipcodeRef.current.value
        }

        if (herdIndex === -1) {
            const id = globalContacts[globalContactIndex].id;
            await api.updateContact(id, data);
            await getGlobalContacts();
        } else {
            const id = herdContacts[herdIndex][herdContactIndex].id;
            await api.updateContact(id, data);
            await getHerds();
        }

        hideModal();
    }

    const deleteContact = async () => {
        if (herdIndex === -1) {
            const id = globalContacts[globalContactIndex].id;
            await api.deleteCustomerContact(id);
            await getGlobalContacts();
        } else {
            const id = herdContacts[herdIndex][herdContactIndex].id;
            await api.deleteHerdContact(id, herds[herdIndex].id);
            await getHerds();
        }

        hideModal();
    }

    const hideModal = () => {
        setEditModalVisible(false);
        setNewModalVisible(false);

        newNameRef.current.value = "";
        newEmailRef.current.value = "";
        newMobileNumRef.current.value = "";
        newAddr1Ref.current.value = "";
        newAddr2Ref.current.value = "";
        newCityRef.current.value = "";
        newZipcodeRef.current.value = "";
    }

    const validateContact = (name, email) => {
        if (name === "") {
            setModalError("Name cannot be empty");
            return false;
        } else if (email === "") {
            setModalError("Email cannot be empty");
            return false;
        }

        return true;
    }

    const openNewHerdModal = (index) => {
        setHerdIndex(index);
        setNewModalVisible(true);
    }

    const openNewGlobalModal = () => {
        setHerdIndex(-1);
        setNewModalVisible(true);
    }

    const openEditHerdModal = (herd_index, contact_index) => {
        const c = herdContacts[herd_index][contact_index];
        editNameRef.current.value = c.name;
        editEmailRef.current.value = c.email;
        editMobileNumRef.current.value = c.mobile_num;
        editAddr1Ref.current.value = c.addr1;
        editAddr2Ref.current.value = c.addr2;
        editCityRef.current.value = c.city;
        setStateIndex(c.state === ""? 0: state_options.findIndex(e => e.abbreviation === c.state));
        editZipcodeRef.current.value = c.zipcode;

        setHerdIndex(herd_index);
        setHerdContactIndex(contact_index);
        setEditModalVisible(true);
    }

    const openEditGlobalModal = (index) => {
        const c = globalContacts[index];
        editNameRef.current.value = c.name;
        editEmailRef.current.value = c.email;
        editMobileNumRef.current.value = c.mobile_num;
        editAddr1Ref.current.value = c.addr1;
        editAddr2Ref.current.value = c.addr2;
        editCityRef.current.value = c.city;
        setStateIndex(c.state === ""? 0: state_options.findIndex(e => e.abbreviation === c.state));
        editZipcodeRef.current.value = c.zipcode;

        setHerdIndex(-1);
        setGlobalContactIndex(index);
        setEditModalVisible(true);
    }

    return (
        <div className="CustomerContactsScreen">
            <h2>Customer Contacts</h2>
            <p>Customer Contacts can receive test results for every herd.</p>
            <Button text="Add Customer Contact" onClick={openNewGlobalModal}/>
            <div style={{height: 16}} />
            {
                globalContacts.map((val, index) => {
                    return <ContactCard contact={val} key={index} API={api} editContact={() => {openEditGlobalModal(index);}}/>
                })
            }

            <h2>Herd Contacts</h2>
            <p>Herd Contacts can only receive test results for a single herd.</p>

            {
                herds.map((val, herd_index) => {
                    return (
                        <div key={herd_index}>
                            <h3 key={herd_index}>{val.name}</h3>
                            <Button text="Add Contact" onClick={() => {openNewHerdModal(herd_index);}}/>
                            <div style={{height: 16}} />
                            {
                                herdContacts[herd_index].map((val, contact_index) => {
                                    return <ContactCard contact={val} key={contact_index} API={api} editContact={() => {openEditHerdModal(herd_index, contact_index);}}/>
                                })
                            }
                        </div>
                    );
                })
            }
            <Modal
                title="New Contact"
                visible={newModalVisible}
                primaryText="Save"
                onPrimary={addContact}
                secondaryText="Cancel"
                onSecondary={hideModal}
                error={modalError}
            >
                <TextInput
                    placeholder="Name"
                    innerRef={newNameRef}
                />
                <TextInput
                    placeholder="Email"
                    innerRef={newEmailRef}
                />
                <TextInput placeholder="Mobile Number (Optional)" innerRef={newMobileNumRef} />
                <p>Address</p>
                <TextInput placeholder="Address 1 (Optional)" innerRef={newAddr1Ref}/>
                <TextInput placeholder="Address 2 (Optional)" innerRef={newAddr2Ref}/>
                <TextInput placeholder="City (Optional)" innerRef={newCityRef}/>
                <SelectInput placeholder="State (Optional)" options={state_options} selectedIndex={stateIndex} onSelect={setStateIndex}/>
                <TextInput placeholder="Zipcode (Optional)" innerRef={newZipcodeRef}/>
            </Modal>
            <Modal
                title="Edit Contact"
                visible={editModalVisible}
                primaryText="Save"
                onPrimary={editContact}
                secondaryText="Cancel"
                onSecondary={hideModal}
                ternaryText="Delete"
                onTernary={deleteContact}
                error={modalError}
            >
                <TextInput
                    placeholder="Name"
                    innerRef={editNameRef}
                />
                <TextInput
                    placeholder="Email"
                    innerRef={editEmailRef}
                />
                <TextInput placeholder="Mobile Number (Optional)" innerRef={editMobileNumRef} />
                <p>Address</p>
                <TextInput placeholder="Address 1 (Optional)" innerRef={editAddr1Ref}/>
                <TextInput placeholder="Address 2 (Optional)" innerRef={editAddr2Ref}/>
                <TextInput placeholder="City (Optional)" innerRef={editCityRef}/>
                <SelectInput placeholder="State (Optional)" options={state_options} selectedIndex={stateIndex} onSelect={setStateIndex}/>
                <TextInput placeholder="Zipcode (Optional)" innerRef={editZipcodeRef}/>
            </Modal>
        </div>
    );
}

export default CustomerContacts;
