import {useRef, useState} from 'react';
import '../styles/FilesUpload.scss';
import {ReactComponent as UploadLogo} from '../assets/cloud_upload-24px.svg';
import Reorder from 'react-reorder';
import Checkbox from './Checkbox';

const FilesUpload = (props) => {
    
    const ref = useRef(null);
    const setFiles = props.onChange;
    let manual = props.manual? props.manual: false;
    const setManual = props.setManual? props.setManual: () => {};

    const onChange = () => {
        if (ref.current.files.length > 0) {
            const files: [File] = Array.from(ref.current.files);
            const rptFiles = files.filter(val => /.*\.rpt/.test(val.name.toLowerCase()));
            const outFiles = files.filter(val => /.*\.out/.test(val.name.toLowerCase()));

            setFiles({
                rpt: rptFiles,
                out: outFiles
            });
        } else {
            setFiles({rpt: [], out: []});
        }
    }

    const files = props.files;
    const rptFiles = files.rpt;
    const outFiles = files.out;

    const filesSelected = !(rptFiles.length === 0 && outFiles.length === 0);
    const text = filesSelected? "Rechoose files": "Choose some files to upload";
    
    const rptCallback = (event, itemMoved, prevIndex, newIndex, newArray) => {
        const f = files;
        f.rptFiles = newArray;
        setFiles(f);
    }

    const outCallback = (event, itemMoved, prevIndex, newIndex, newArray) => {
        const f = files;
        f.outFiles = newArray;
        setFiles(f);
    }
    
    return (
        <div className="FilesUpload">
            <input key={props.innerKey} type="file" id={"RPT"}  ref={ref} accept=".rpt,.out" multiple onChange={onChange}/>
            <div className="horizontal">
                <label htmlFor={props.fileExt}>
                    <UploadLogo />
                    {text}
                    
                </label>
                {
                    filesSelected?
                    <Checkbox
                        label="Arrange Manually"
                        checked={manual}
                        setChecked={setManual}
                    />:
                    <div />
                }
                <Checkbox
                    label="Force Upload"
                    checked={props.force}
                    setChecked={props.setForce}
                />

            </div>
            
        {
            !filesSelected?
            <div />
            :
            <div className="table">
                <div className="rpt">
                    <p className="header">RPT</p>
                    <Reorder
                        itemKey="name"
                        lock="horizontal"
                        list={rptFiles}
                        template={ListItem}
                        disableReorder={!manual}
                        callback={rptCallback}
                    />
                </div>
                <div className="out">
                    <p className="header">OUT</p>
                    <Reorder
                        itemKey="name"
                        lock={"horizontal"}
                        list={outFiles}
                        template={ListItem}
                        disableReorder={!manual}
                        callback={outCallback}
                    />
                </div>
            </div>
        }
            
        </div>
    );
}

const ListItem = (props) => {
    const file = props.item;

    return <p className="listitem">{file.name}</p>
}


export default FilesUpload;
