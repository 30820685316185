import '../styles/Nav.scss';
import logo from '../assets/logo.svg';
import Button from './Button';
import { NavLink } from 'react-router-dom';

const Nav = (props) => {

    const scrollRef = props.scrollRef;

    const logout = () => {
        localStorage.clear();
        window.location.href = "/cloud/login";
    }

    return (
        <div className="nav-container">
            <div className="nav-logo">
                <img src={logo} alt="Logo" width={68} height={50}/>
                <p>High Desert Dairy Lab</p>
            </div>
            <div className="nav-header">
                <Button text="Logout" onClick={logout}/>
            </div>
            <div className="nav-bar">
                <NavLink to="/cloud" activeClassName="selected" exact>Results</NavLink>
                <NavLink to="/cloud/reports" activeClassName="selected" exact>Reports</NavLink>
                <NavLink to="/cloud/analytics" activeClassName="selected" exact>Analytics</NavLink>
                <NavLink to="/cloud/contacts" activeClassName="selected" exact>Contacts</NavLink>
            </div>
            <div className="nav-content" ref={scrollRef}>
                {props.children}
            </div>
        </div>
    );
}


export default Nav;