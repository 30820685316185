import '../styles/InvoiceDetail.scss';

import Button from '../components/Button';

const InvoiceDetail = (props) => {
    const invoice = props.invoice;
    const editItem = props.editItem;
    const newLineItem = props.newLineItem;
    const sendInvoice = props.sendInvoice;

    const formatCurrency = (num) => {
        return num.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
    }

    return (
        <div className="InvoiceDetail">
            <div className="Detail">
                <div style={{paddingBottom: 16}}>
                    <div className="CustomerTitle">{invoice.cust.name}</div>
                    <div className="Subtext">{formatCurrency(invoice.total)}</div>
                </div>
                <Button text="Send Invoice" onClick={sendInvoice}/>
                <div style={{marginTop: '8px'}} />
                <Button text="Add Line Item" type="text" onClick={newLineItem}/>
            </div>
            <table className="LineItems">
                <tbody>
                    <tr>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Total</th>
                        <th>Supply Date</th>
                        <th></th>
                    </tr>
                {
                    invoice.line_items.length === 0? <div className="NoLineItems" style={{alignSelf: "center", paddingTop: 16}}>No Line Items</div>:
                    invoice.line_items.map((val, index) => {
                        return <LineItemCard key={index} lineItem={val} editItem={editItem}/>
                    })
                }
                </tbody>
            </table>
        </div>
    );
}

const LineItemCard = (props) => {
    const lineItem = props.lineItem;
    const id = lineItem.id;
    const description = lineItem.description;
    const rate = lineItem.rate;
    const quantity = lineItem.quantity;
    const total = lineItem.total;
    const editItem = props.editItem;
    const supplyDate = new Date(lineItem.supply_date).toLocaleDateString();

    const currency = (num) => {
        return num.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
    }

    return (
        <tr className="LineItem">
            <td className="Description">{description}</td>
            <td className="Quantity">{quantity}</td>
            <td className="Rate">{currency(rate)}</td>
            <td className="Total">{currency(total)}</td>
            <td>{supplyDate}</td>
            <td><Button type="text" text="Edit" onClick={() => {editItem(id);}}/></td>
        </tr>
    );
}

export default InvoiceDetail;
