import '../styles/Checkbox.scss';
import {ReactComponent as CheckIcon} from '../assets/check-24px.svg';

const Checkbox = (props) => {
    const checked = props.checked;
    const setChecked = props.setChecked;

    const onChange = (evt) => {
        setChecked(evt.target.checked);
    }

    return (
        <div className="CheckContainer">
            <div className={"Checkbox" + (checked? " checked": "")} onClick={() => {setChecked(!checked)}}>
                <CheckIcon className={checked? "": "hidden"}/>
                <input type="checkbox" onChange={onChange} checked={checked}/>
            </div>
            <div className="label">{props.label}</div>
        </div>
    );
}

export default Checkbox;