import '../styles/ContactCard.scss';
import {useState, useEffect} from 'react';
import Button from './Button';
import Checkbox from './Checkbox';
import SelectInput from './SelectInput';
import API from '../util/CustAPI';

const ContactCard = (props) => {
    const contact = props.contact;
    const api: API = props.API;
    const editContact = props.editContact? props.editContact: () => {};

    const options = [
        {name: "Link", id: "LINK"},
        {name: "DTA", id: "DTA"},
        {name: "CSV", id: "CSV"},
        {name: "XLSX (Excel)", id: "XLSX"},
    ]

    const initial_index = contact.test_format === null? 0: options.findIndex((e) => e.id === contact.test_format);

    const [ancmtChecked, setAncmtChecked] = useState(contact.receive_announcements);
    const [billingChecked, setBillingChecked] = useState(contact.receive_billing);
    const [testsChecked, setTestsChecked] = useState(contact.receive_tests);
    const [drymatterChecked, setDrymatterChecked] = useState(contact.receive_drymatter);
    const [selectedIndex, setSelectedIndex] = useState(initial_index);

    const updatePrefs = async () => {
        const data = {
            receive_announcements: ancmtChecked,
            receive_tests: testsChecked,
            receive_billing: billingChecked,
            receive_drymatter: drymatterChecked
        }

        if (testsChecked) {
            data["test_format"] = options[selectedIndex].id;
        }

        await api.updateContact(contact.id, data);
    }

    const fmtAddress = () => {
        const a = (b, c) => {
            return b? b + c: ""
        }

        const address = (
            a(contact.addr1, "\n") +
            a(contact.addr2, "\n") +
            a(contact.city, ", ") +
            a(contact.state, ". ") +
            a(contact.zipcode, ".")
        )

        return address;
    }

    useEffect(() => {
        updatePrefs();
    }, [ancmtChecked, billingChecked, testsChecked, selectedIndex, drymatterChecked])

    return (
        <div className="ContactCard">
            <div className="Detail">
                <div>
                    <p className="Title">{contact.name}</p>
                    <p>{contact.email}</p>
                    <p>{contact.mobile_num}</p>
                    <p className="Address">{fmtAddress()}</p>
                </div>
                <Button text="Edit Contact Information" type="text" onClick={editContact}/>
            </div>
            <div className="Preferences">
                <Checkbox label="Receive Announcements" checked={ancmtChecked} setChecked={setAncmtChecked}/>
                <Checkbox label="Receive Bill by Email" checked={billingChecked} setChecked={setBillingChecked} />
                <Checkbox label="Receive Test Results" checked={testsChecked} setChecked={setTestsChecked}/>
                <div style={{height: 4}} />
                <SelectInput 
                    options={options} 
                    className={testsChecked? "": "hidden"}
                    selectedIndex={selectedIndex}
                    onSelect={setSelectedIndex}
                    placeholder="Receive As"
                />
                <Checkbox label="Receive Dry Matter Results" checked={drymatterChecked} setChecked={setDrymatterChecked} />
            </div>
        </div>
    );
}

export default ContactCard;
