import '../styles/TextInput.scss';
import {useRef} from 'react';

const CurrencyInput = (props) => {
    const value = props.value;

    const placeholder = props.placeholder? props.placeholder: "";

    const onBlur = () => {
        if (value !== "") {
            let evt = {
                target: {
                    value: value
                }
            };
            evt.target.value = Number(value).toFixed(2);

            if (Number(value) < 0) {
                evt.target.value = "0.00"
            }

            props.onChange(evt);
        }
    }

    const onChange = (evt) => {
        let val = evt.target.value;
        let parts = val.split(".");
        if (!isNaN(val) && (parts.length<2 || parts[1].length<3)) {
            props.onChange(evt);
        }
    }

    const defaultValue = props.value;

    return (
        <div className="TextInput CurrencyInput">
            <input
                required={true}
                onBlur={onBlur}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
            />
            <label>{placeholder}</label>
        </div>
    );
}

export default CurrencyInput;