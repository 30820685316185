import '../styles/login.scss';

import {useState, useEffect, useRef} from 'react';
import { useHistory } from "react-router-dom";

import Button from '../components/Button';
import TextInput from '../components/TextInput';
import {ReactComponent as Logo} from '../assets/logo.svg';
import {Link, Redirect} from 'react-router-dom';

const Login = (props) => {
    const [error, setError] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const history = useHistory();

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const api = props.API;

    useEffect(() => {
        const alert = (err) => {
            setError(err);
            setLoggedIn(api.loggedIn);
        }

        api.addListener(alert);
        return () => {api.removeListener(alert)}
    }, [api]);

    // Validate the user input
    const validate = (email, password) => {
        if (!email) {
            setError("Email cannot be empty");
        } else if (!password) {
            setError("Password cannot be empty");
        }

        return true;
    }

    // Attempt a login
    const login = () => {
        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        if (validate(email, password)) {
            api.login(email, password);
        }
    }

    const reset = () => {
        history.push("/cloud/request_reset");
    }

    return (
        <div className="LoginScreen">
            {loggedIn? <Redirect to={{pathname: "/cloud"}} />: <div />}
            <div className="LoginHeader">
                <Logo />
                <p>High Desert Dairy</p>
            </div>
            <div className="LoginCard">
                <p>Login</p>
                <form>
                    <TextInput placeholder="Email / Customer ID" autocomplete="username" innerRef={emailRef}/>
                    <TextInput placeholder="Password" type="password" autocomplete="current-password" innerRef={passwordRef}/>
                    <p className="Error">{error}</p>
                    <div className="Buttons">
                        <Button text="Login" onClick={login} submit={true}/>
                        <Button text="Forgot Password" type="text" onClick={reset}/>
                    </div>
                </form> 
            </div>
        </div>
    );
}

export default Login;
