import '../styles/Button.scss';

const Button = (props) => {

    const type = props.type? props.type: "filled";
    const size = props.size? props.size: "";
    const className =  "button-" + type + " " + size;
    const buttonType = props.submit? "submit": "button";

    const onClick = (e) => {
        e.preventDefault();
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <button className={className} onClick={onClick} type={buttonType}>
            {props.text}
        </button>
    );
}

export default Button;
